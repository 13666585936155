import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {ManageInitiative} from '../models/manage-initiative.model';
import {ManagePlace} from '../models/manage-place.model';
import {Event} from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class ManageDataService {

  uploadResponse = new BehaviorSubject<any>(null);
  uploadProgressSubject = new BehaviorSubject<number>(null);
  userInitiativesSubject = new BehaviorSubject<ManageInitiative[]>([]);
  userPlacesSubject = new BehaviorSubject<ManagePlace[]>([]);
  eventsSubject = new BehaviorSubject<Event[]>([]);
  myEventsSubject = new BehaviorSubject<Event[]>([]);

  hours = [
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30'
  ];

  constructor(private http: HttpClient) {
  }

  getFestivals(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/festivals`);
  }

  getAreaSettings(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/area-settings`);
  }

  getCities(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/cities`);
  }

  getUsersStatuses(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/user-statuses`);
  }

  getEditUserData(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/edit-user-data`);
  }

  getCommunities(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/communities`);
  }

  getEditFestivalData(festivalID): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/edit-festival?festivalID=${festivalID}`);
  }

  getNeighborhoods(cityID): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/neighborhoods?cityID=${cityID}`);
  }

  getGenres(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/genres`);
  }

  getPublics(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/publics`);
  }

  getTags(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/tags`);
  }

  getMyInitiativesSubscription(festivalEnglishName, initiativeID = null): any {
    let url = `${environment.apiURL}/my-initiative?festival=${festivalEnglishName}`;
    if (initiativeID) {
      url += `&initiativeID=${initiativeID}`;
    }
    this.http.get(url).subscribe((initiatives: ManageInitiative[]) => {
      if (initiatives) {
        this.userInitiativesSubject.next(initiatives);
      }
    });
  }

  buildFilterUrlParams(filters): string {
    let url = '';
    if (filters) {
      if (filters.filterByAreaSettings) {
        url += `&areaSettingValue=${filters.filterByAreaSettings}`;
      }
      if (filters.filterByNeighborhood) {
        url += `&neighborhoodID=${filters.filterByNeighborhood}`;
      }
      if (filters.filterByGenre) {
        url += `&genre=${filters.filterByGenre}`;
      }
      if (filters.filterByNumberOfParticipants) {
        url += `&numberOfParticipants=${filters.filterByNumberOfParticipants}`;
      }
      if (filters.filterByPublic) {
        url += `&public=${filters.filterByPublic}`;
      }
      if (filters.filterByParking) {
        url += `&parking=${filters.filterByParking}`;
      }
      if (filters.filterByAccessible) {
        url += `&accessible=${filters.filterByAccessible}`;
      }
      if (filters.filterByPetFriendly) {
        url += `&petFriendly=${filters.filterByPetFriendly}`;
      }
      if (filters.filterByMakeNoise) {
        url += `&makeNoise=${filters.filterByMakeNoise}`;
      }
      if (filters.filterByPiano) {
        url += `&piano=${filters.filterByPiano}`;
      }
    }
    return url;
  }

  addFormData(localStorageKey: string, step: number, data): void {
    // get form data from local storage
    let localStorageData = this.getLocalStorageData(localStorageKey);

    if (!localStorageData) {
      localStorageData = {};
    }
    // add new data to object taken from local storage
    localStorageData[step] = data;

    // save new object to local storage
    this.setLocalStorage(localStorageKey, localStorageData);
  }

  setLocalStorage(localStorageKey: string, localStorageData): void {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(localStorageData));
    }
  }

  getLocalStorageData(localStorageKey: string): object {
    return JSON.parse(localStorage.getItem(localStorageKey));
  }

  save(localStorageKey, festivalEnglishName): void {
    const formData = this.getLocalStorageData(localStorageKey);
    const sendParams = new URLSearchParams();
    sendParams.append('data', JSON.stringify(formData));

    const endPoint = localStorageKey === 'addPlaceFormData' ? 'place' : 'initiative';
    this.http.post(`${environment.apiURL}/${endPoint}?festival=${festivalEnglishName}`, sendParams.toString(), {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
    }).subscribe(res => {
      if (res) {
        this.setLocalStorage(localStorageKey, null);
        this.uploadResponse.next(null);
      }
    });
  }

  updateUser(userID, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/users?userID=${userID}`, JSON.stringify(data));
  }

  onFileUpload(festivalEnglishName, uploadedFile, userID = null, imageTarget = null): void {
    setTimeout(() => {
      const fd = new FormData();
      fd.append('action', 'upload');
      fd.append('file', uploadedFile);
      fd.append('filename', uploadedFile.name);
      fd.append('userID', userID);
      fd.append('imageTarget', imageTarget);
      fd.append('localeTimeStamp', moment().format('YYYY-MM-DD HH:mm:ss'));
      this.http.post(`${environment.apiURL}/file?festival=${festivalEnglishName}`, fd, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgressSubject.next(Math.round(event.loaded / event.total * 100));
        } else if (event.type === HttpEventType.Response) {
          this.uploadProgressSubject.next(0);
          this.uploadResponse.next(event);
        }
      });
    }, 500);
  }

  sendContactMessage(festivalEnglishName, data): Observable<any> {
    return this.http.post(`${environment.apiURL}/contact?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  deleteGenre(festivalID, genreID): Observable<any> {
    return this.http.delete(`${environment.apiURL}/data?festivalID=${festivalID}&genreID=${genreID}`);
  }

  addGenre(festivalID, genre): Observable<any> {
    return this.http.post(`${environment.apiURL}/data?festivalID=${festivalID}`, JSON.stringify({genre}));
  }
}
