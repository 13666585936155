import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {EventsListComponent} from './events-list/events-list.component';
import {PlacesListComponent} from './places-list/places-list.component';
import {InitiativesListComponent} from './initiatives-list/initiatives-list.component';
import {CommunitiesListComponent} from './communities-list/communities-list.component';
import {EditCommunityComponent} from './edit-community/edit-community.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {EditInitiativeComponent} from './edit-initiative/edit-initiative.component';
import {EditPlaceComponent} from './edit-place/edit-place.component';
import {CulturalInitiativesListComponent} from './cultural-initiatives-list/cultural-initiatives-list.component';
import {CulturalInitiativeComponent} from './cultural-initiative/cultural-initiative.component';
import {EventComponent} from './event/event.component';
import {PlaceComponent} from './place/place.component';
import {InitiativeComponent} from './initiative/initiative.component';
import {EditCulturalInitiativeComponent} from './edit-cultural-initiative/edit-cultural-initiative.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {UserComponent} from './user/user.component';
import {UsersListComponent} from './users-list/users-list.component';
import {AddUserComponent} from './add-user/add-user.component';
import {AddCulturalInitiativeComponent} from './add-cultural-initiative/add-cultural-initiative.component';
import {EditCulturalInitiativeAboutComponent} from './edit-cultural-initiative-about/edit-cultural-initiative-about.component';
import {EditCulturalInitiativeNavigationComponent} from './edit-cultural-initiative-navigation/edit-cultural-initiative-navigation.component';
import {EditCulturalInitiativeTeamComponent} from './edit-cultural-initiative-team/edit-cultural-initiative-team.component';
import {EditCulturalInitiativeThanksComponent} from './edit-cultural-initiative-thanks/edit-cultural-initiative-thanks.component';
import {EditCulturalInitiativeSleepComponent} from './edit-cultural-initiative-sleep/edit-cultural-initiative-sleep.component';
import {EditCulturalInitiativeUpdatesComponent} from './edit-cultural-initiative-updates/edit-cultural-initiative-updates.component';
import {AddCommunityComponent} from './add-community/add-community.component';
import {AddEventComponent} from './add-event/add-event.component';
import {EditEventComponent} from './edit-event/edit-event.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'communities-list', component: CommunitiesListComponent, canActivate: [AuthGuardService]},
  {path: 'community/:communityID', component: CommunitiesListComponent, canActivate: [AuthGuardService]},
  {path: 'edit-community/:communityID', component: EditCommunityComponent, canActivate: [AuthGuardService]},
  {path: 'add-community', component: AddCommunityComponent, canActivate: [AuthGuardService]},
  {path: 'cultural-initiatives-list', component: CulturalInitiativesListComponent, canActivate: [AuthGuardService]},
  {path: 'cultural-initiative/:subdomain', component: CulturalInitiativeComponent, canActivate: [AuthGuardService]},
  {path: 'add-cultural-initiative', component: AddCulturalInitiativeComponent, canActivate: [AuthGuardService]},
  {
    path: 'edit-cultural-initiative/:subdomain', component: EditCulturalInitiativeComponent, canActivate: [AuthGuardService],
    children: [
      {path: 'about', component: EditCulturalInitiativeAboutComponent},
      {path: 'navigation', component: EditCulturalInitiativeNavigationComponent},
      {path: 'team', component: EditCulturalInitiativeTeamComponent},
      {path: 'thanks', component: EditCulturalInitiativeThanksComponent},
      {path: 'sleep', component: EditCulturalInitiativeSleepComponent},
      {path: 'updates', component: EditCulturalInitiativeUpdatesComponent},
    ]
  },
  {path: 'events-list', component: EventsListComponent, canActivate: [AuthGuardService]},
  {path: 'event/:eventID', component: EventComponent, canActivate: [AuthGuardService]},
  {path: 'add-event', component: AddEventComponent, canActivate: [AuthGuardService]},
  {path: 'edit-event/:eventID', component: EditEventComponent, canActivate: [AuthGuardService]},
  {path: 'places-list', component: PlacesListComponent, canActivate: [AuthGuardService]},
  {path: 'place/:placeID', component: PlaceComponent, canActivate: [AuthGuardService]},
  {path: 'edit-place/:placeID', component: EditPlaceComponent, canActivate: [AuthGuardService]},
  {path: 'initiatives-list', component: InitiativesListComponent, canActivate: [AuthGuardService]},
  {path: 'initiative/:initiativeID', component: InitiativeComponent, canActivate: [AuthGuardService]},
  {path: 'edit-initiative/:initiativeID', component: EditInitiativeComponent, canActivate: [AuthGuardService]},
  {path: 'users-list', component: UsersListComponent, canActivate: [AuthGuardService]},
  {path: 'user/:userID', component: UserComponent, canActivate: [AuthGuardService]},
  {path: 'add-user', component: AddUserComponent, canActivate: [AuthGuardService]},
  {path: 'edit-user/:userID', component: EditUserComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
