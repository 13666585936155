import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TextEditorService} from '../shared/services/text-editor.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-cultural-initiative-thanks',
  templateUrl: './edit-cultural-initiative-thanks.component.html',
  styleUrls: ['./edit-cultural-initiative-thanks.component.css']
})
export class EditCulturalInitiativeThanksComponent implements OnInit {
  @Input() content: string;
  @Output() newItemEvent = new EventEmitter<string>();
  editorConfig;

  constructor(
    private textEditorService: TextEditorService,
  ) {
  }

  ngOnInit(): void {
    this.editorConfig = this.textEditorService.editorConfig;
  }

  contentChanged(): void {
    this.newItemEvent.emit(this.content);
  }

}
