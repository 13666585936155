import {Pipe, PipeTransform} from '@angular/core';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';

@Pipe({
  name: 'filterCulturalInitiativesList'
})
export class FilterCulturalInitiativesListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): CulturalInitiative[] {
    if (!value || value.length === 0 || args.length === 0 || !args) {
      return;
    }
    let filteredObjects;
    filteredObjects = value.filter(item => {
      return (
        (item.festivalID.toString().includes(args)) ||
        (item.name && item.name.includes(args)) ||
        (item.city && item.city.includes(args)) ||
        (item.fromDate && item.fromDate.includes(args)) ||
        (item.untilDate && item.untilDate.includes(args)) ||
        (item.subdomain && item.subdomain.includes(args))
      );
    });
    return filteredObjects;
  }

}
