import {Pipe, PipeTransform} from '@angular/core';
import {ManageUser} from '../shared/models/manage-user.model';

@Pipe({
  name: 'filterUsersListByObject'
})
export class FilterUsersListByObjectPipe implements PipeTransform {

  transform(value: any, ...args: { festival: string, city: string, status: string }[]): ManageUser[] {
    if (!value || value.length === 0) {
      return;
    }
    let resultArray;
    let filteredObjects = [];
    let festival;
    let city;
    let status;

    if (args[0]) {
      festival = args[0].festival;
      city = args[0].city;
      status = args[0].status;
    }
    if (args[0]) {
      filteredObjects = value;
      if (festival) {
        filteredObjects = value.filter(item => item.festivals.includes(festival));
      }
      if (city) {
        filteredObjects = filteredObjects.filter(item => item.city === city);
      }
      if (status) {
        filteredObjects = filteredObjects.filter(item => item.status.includes(status));
      }
      resultArray = filteredObjects;
    } else {
      resultArray = value;
    }
    return resultArray;
  }

}
