import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {User} from './shared/models/user.model';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'הנדיב - מערכת ניהול';
  user: User;
  userSubscription: Subscription;
  previousUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
        if (this.router.url === '/login' || this.previousUrl === '/') {
          if (user.manageSystem) {
            this.router.navigate(['/communities-list']);
          } else if (user.manageCommunity) {
            this.router.navigate(['/cultural-initiatives-list']);
          } else if (user.manageFestival) {
            this.router.navigate(['/cultural-initiative', this.user.adminHomePage]);
          } else {
            this.router.navigate(['/login']);
          }
        }
      }
    });
    this.authService.autoLogin();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
