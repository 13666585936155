import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {faEye, faEdit, faTrash, faSort, faFilter} from '@fortawesome/free-solid-svg-icons';
import {InitiativeService} from '../shared/services/initiative-service.service';
import {ManageInitiative} from '../shared/models/manage-initiative.model';
import {FormControl, FormGroup} from '@angular/forms';
import {ManageDataService} from '../shared/services/manage-data.service';
import {FilterInitiativesListByObjectPipe} from './filter-initiatives-list-by-object.pipe';
import {Genre} from '../shared/models/genre.model';
import {PublicModel,} from '../shared/models/publics-area-settings.model';
import {FilterInitiativesListPipe} from './filter-initiatives-list.pipe';
import {Festival} from '../shared/models/festival.model';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-initiatives-list',
  templateUrl: './initiatives-list.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './initiatives-list.component.css'
  ]
})
export class InitiativesListComponent implements OnInit, OnDestroy {

  user: User;
  initiatives: ManageInitiative[];
  userSubscription: Subscription;
  festivalsSubscription: Subscription;
  initiativesSubscription: Subscription;
  categoriesSubscription: Subscription;
  publicsSubscription: Subscription;
  deleteSubscription: Subscription;
  filterForm: FormGroup;
  festivals: Festival[];
  categories: Genre[];
  publics: PublicModel[];
  matchedWithPlaceOptions: { title: string, value: number }[];
  totalText: string;
  numberOfFilteredResults: number;
  key = 'initiativeID';
  reverse = false;
  search: string;
  filtered: boolean;
  filterByObject: { festivalID: number, matchedWithPlace: number, category: string, publicValue: string };
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faSort = faSort;
  faFilter = faFilter;

  constructor(
    private authService: AuthService,
    private initiativeService: InitiativeService,
    private manageDataService: ManageDataService,
    private filterByText: FilterInitiativesListPipe,
    private filterByObjectPipe: FilterInitiativesListByObjectPipe,
  ) {
  }

  ngOnInit(): void {

    this.getInitiatives();
    this.buildForm();

    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.categoriesSubscription = this.manageDataService.getGenres().subscribe(categories => {
      this.categories = categories;
    });

    this.publicsSubscription = this.manageDataService.getPublics().subscribe(publics => {
      this.publics = publics;
    });

    this.festivalsSubscription = this.manageDataService.getFestivals().subscribe(festivals => {
      this.festivals = festivals;
    });

    this.matchedWithPlaceOptions = [
      {title: 'לא משודך', value: 0},
      {title: 'כן משודך', value: 1},
    ];
  }

  getInitiatives(): void {
    this.initiativesSubscription = this.initiativeService.get().subscribe(data => {
      this.initiatives = data;
      const totalNumber = data.length;

      const reducer = (accumulator, item) => {
        return accumulator + item.existingEventWithInitiativeStatus;
      };
      const totalNumberOfMatched = data.reduce(reducer, 0);

      const text = 'יוזמות משודכות';
      this.totalText = `${text} ${totalNumberOfMatched}/${totalNumber}`;
    });
  }

  buildForm(): void {
    this.filterForm = new FormGroup({
      festivalID: new FormControl(''),
      matchedWithPlace: new FormControl(''),
      category: new FormControl(''),
      publicValue: new FormControl(''),
    });
  }

  onFilter(): void {
    this.filtered = true;
    this.filterByObject = this.filterForm.value;
    if (!this.filterByObject.festivalID && !this.filterByObject.matchedWithPlace.toString() && !this.filterByObject.category && !this.filterByObject.publicValue) {
      this.clearFilter();
    } else {
      this.countFilteredResults();
    }
  }

  clearFilter(): void {
    this.filtered = false;
    this.filterForm.controls.festivalID.setValue('');
    this.filterForm.controls.matchedWithPlace.setValue('');
    this.filterForm.controls.category.setValue('');
    this.filterForm.controls.publicValue.setValue('');
    this.filterByObject = this.filterForm.value;
    this.numberOfFilteredResults = null;
  }

  countFilteredResults(): void {
    const filteredCulturalInitiatives = this.filterByObjectPipe.transform(this.initiatives, this.filterForm.value);
    this.numberOfFilteredResults = filteredCulturalInitiatives.length;
  }

  countSearchResults(): void {
    const filteredResults = this.filterByText.transform(this.initiatives, this.search);
    if (this.search === '') {
      this.numberOfFilteredResults = null;
    } else {
      this.numberOfFilteredResults = filteredResults.length;
    }
  }

  delete(id): void {
    const confirmDelete = confirm(`האם את/ה בטוח שאת/ה רוצה למחוק את יוזמה מספר ${id}?`);
    if (confirmDelete) {
      this.deleteSubscription = this.initiativeService.delete(id).subscribe(res => {
        this.getInitiatives();
      });
    }
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  ngOnDestroy(): void {
    this.initiativesSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
    this.publicsSubscription.unsubscribe();
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }
}
