import {Component, Input, OnInit} from '@angular/core';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-item-button',
  templateUrl: './add-item-button.component.html',
  styleUrls: ['./add-item-button.component.css']
})
export class AddItemButtonComponent implements OnInit {

  @Input() addItemLink: string;
  faPlusCircle = faPlusCircle;

  constructor() {
  }

  ngOnInit(): void {
  }

}
