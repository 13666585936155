import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Festival} from '../models/festival.model';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FestivalService {
  festivals: Festival[];
  festivalsSubject = new BehaviorSubject<Festival[]>(null);
  currentFestivalSubject = new BehaviorSubject<Festival>(null);
  festivalIsOverSubject = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private router: Router
  ) {

  }

  onGetFestivals(): void {
    this.http.get<Festival[]>(`${environment.apiURL}/festival`).subscribe(festivals => {
      this.festivals = festivals;
      this.festivalsSubject.next(festivals);
    });
  }

  onGetCurrentFestival(festivalEnglishName): Festival {
    const festival = this.festivals.find(item => item.festivalEnglishName === festivalEnglishName);
    if (!festival) {
      // this.router.navigate([`/festivals/list`]);
    }
    this.currentFestivalSubject.next(festival);
    return festival;
  }

  checkIfFestivalIsOver(festival: Festival): any {
    const currentDate = moment().format();
    const isTodayBeforeFestivalDates = moment(currentDate).isBefore(festival.fromDate);
    const isTodayBetweenFestivalDates = moment(currentDate).isBetween(festival.fromDate, festival.untilDate, undefined, '[]');
    const festivalIsOver = !isTodayBeforeFestivalDates && !isTodayBetweenFestivalDates;
    this.festivalIsOverSubject.next(festivalIsOver);
  }

}
