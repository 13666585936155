<div>
  <app-list-title
    title="רשימת קהילות"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    subTitle="{{totalText}}">
  </app-list-title>
  <div class="d-flex align-items-center justify-content-space-between">
    <div>
      <label>
        <input type="text" [(ngModel)]="search" class="form-control">
      </label>
    </div>
    <app-add-item-button addItemLink="/add-community"></app-add-item-button>
  </div>
  <table class="table">
    <tr>
      <td class="pointer" (click)="sort('communityID')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>מספר מזהה</span>
      </td>
      <td class="pointer" (click)="sort('title')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>שם קהילה</span>
      </td>
      <td class="pointer" (click)="sort('nextFestivalDate')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>תאריך הפסטיבל הבא?</span>
      </td>
      <td>תאריך הוספה</td>
      <td>פעולות</td>
    </tr>
    <tr *ngFor="let item of communities | filterCommunitiesList:search | orderBy: key: reverse">
      <td>{{item.communityID}}</td>
      <td>{{item.title}}</td>
      <td>{{item.nextFestivalDate}}</td>
      <td>{{formatDate(item.insertTimeStamp)}}</td>
      <td class="d-flex justify-content-space-evenly">
        <button class="btn btn-info" routerLink="/edit-community/{{item.communityID}}">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <!--        <button class="btn btn-danger" (click)="delete(item.communityID)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>-->
      </td>
    </tr>
  </table>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">סינון חברים/ות</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterUsersForm" (submit)="onFilter();modal.close()">
      <label>
        <select formControlName="festivalID" class="form-control">
          <option value="0">בחירת פסטיבל</option>
          <option *ngFor="let item of filterFestivals" [ngValue]="item.festivalID">{{item.name}}</option>
        </select>
      </label>
      <label>
        <select formControlName="cityID" class="form-control">
          <option value="0">בחירת יישוב</option>
          <option *ngFor="let item of filterCities" [ngValue]="item.cityID">{{item.title}}</option>
        </select>
      </label>
      <label>
        <select formControlName="cityID" class="form-control">
          <option value="0">בחירת סטטוס</option>
          <option *ngFor="let item of filterStatuses" [ngValue]="item.statusID">{{item.title}}</option>
        </select>
      </label>
      <div class="text-center mt-5">
        <button class="btn btn-submit font-size-18 pr-3 pl-3" type="submit">סינון</button>
      </div>
    </form>
  </div>
</ng-template>
