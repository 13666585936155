<div class="container-fluid vh-100 mb-5">
  <div class="row page-navbar">
    <span>כניסה</span>
  </div>
  <div class="container">
    <div class="row mt-4 mb-4">
      <div class="col-12 mt-3 intro-title">התחברות למערכת</div>
    </div>
    <div class="text-center" *ngIf="isLoading">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" *ngIf="!isLoading">
      <div class="form-group">
        <label class="mb-4">
          <input type="email" formControlName="email" class="form-control" placeholder="דואר אלקטרוני" autocomplete="off">
        </label>
        <label>
          <input type="password" class="form-control" formControlName="password" placeholder="סיסמא">
        </label>
      </div>
      <div class="text-center">
        <button class="btn btn-submit mb-5 w-50 font-size-22" type="submit" [disabled]="loginForm.invalid">כניסה</button>
      </div>
      <div class="mt-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
      <div class="mt-3 alert alert-success" *ngIf="message">{{message}}</div>
    </form>
    <div class="font-size-14 color-turquoise mt-4" routerLink="/forgot-password" *ngIf="!isLoading">פאדיחות, שכחתי סיסמא</div>
  </div>
</div>
