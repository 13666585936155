import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManageDataService} from '../shared/services/manage-data.service';
import {City} from '../shared/models/city.model';
import {Subscription} from 'rxjs';
import {Community} from '../shared/models/community.model';
import {CulturalInitiativesService} from '../shared/services/cultural-initiatives-service.service';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-cultural-initiative',
  templateUrl: './add-cultural-initiative.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './add-cultural-initiative.component.css'
  ]
})
export class AddCulturalInitiativeComponent implements OnInit, OnDestroy {

  addCulturalInitiativeForm: FormGroup;
  communitiesSubscription: Subscription;
  citiesSubscription: Subscription;
  uploadImageSubscription: Subscription;
  communities: Community[];
  cities: City[];
  date: { year: number, month: number };
  imageSource;
  tabs: {
    about: true,
    navigation: false,
    team: false,
    thanks: false,
    sleep: false,
    updates: false,
  };

  constructor(
    private manageDataService: ManageDataService,
    private culturalInitiativesService: CulturalInitiativesService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setTabs();
    this.buildForm();
    this.communitiesSubscription = this.manageDataService.getCommunities().subscribe(data => {
      this.communities = data;
    });
    this.citiesSubscription = this.manageDataService.getCities().subscribe(data => {
      this.cities = data;
    });

    this.uploadImageSubscription = this.manageDataService.uploadResponse.subscribe(res => {
      if (res) {
        const uploadedFileData = res.body;
        this.setImageSource(uploadedFileData);
      }
    });
  }

  setTabs(): void {
    this.tabs = {
      about: true,
      navigation: false,
      team: false,
      thanks: false,
      sleep: false,
      updates: false,
    };
  }

  buildForm(): void {
    this.addCulturalInitiativeForm = new FormGroup({
      communityID: new FormControl(0, [Validators.required]),
      cityID: new FormControl(0, [Validators.required]),
      name: new FormControl('', [Validators.required]),
      about: new FormControl(''),
      fromDate: new FormControl('', [Validators.required]),
      untilDate: new FormControl('', [Validators.required]),
      subdomain: new FormControl('', [Validators.required]),
      fileHash: new FormControl('', [Validators.required]),
      navigation: new FormControl(''),
      team: new FormControl(''),
      thanks: new FormControl(''),
      sleep: new FormControl(''),
      updates: new FormControl(''),
      facebook: new FormControl(''),
      instagram: new FormControl(''),
      youtube: new FormControl(''),
      metadata: new FormControl(''),
    });
  }

  listenToTextEditorEmits(key, value: string): void {
    this.addCulturalInitiativeForm.controls[key].setValue(value);
  }

  showTab(key): void {
    for (const [index, value] of Object.entries(this.tabs)) {
      this.tabs[index] = key === index;
    }
  }

  setImageSource(uploadedFile): void {
    if (uploadedFile) {
      this.addCulturalInitiativeForm.controls.fileHash.setValue(uploadedFile.relativePath);
      this.imageSource = `${uploadedFile.path}`;
    }
  }

  onSubmit(): void {
    if (this.addCulturalInitiativeForm.status === 'VALID') {
      this.culturalInitiativesService.insert(this.addCulturalInitiativeForm.value).subscribe((res: CulturalInitiative[]) => {
        this.router.navigate(['/cultural-initiatives-list']);
      });
    }
  }

  ngOnDestroy(): void {
    this.communitiesSubscription.unsubscribe();
    this.citiesSubscription.unsubscribe();
    if (this.uploadImageSubscription) {
      this.uploadImageSubscription.unsubscribe();
    }
  }

}
