import {Component, OnDestroy, OnInit} from '@angular/core';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';
import {Subscription} from 'rxjs';
import {CulturalInitiativesService} from '../shared/services/cultural-initiatives-service.service';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from '@angular/router';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-cultural-initiative',
  templateUrl: './cultural-initiative.component.html',
  styleUrls: [
    '../shared/styles/page.css',
    './cultural-initiative.component.css'
  ]
})
export class CulturalInitiativeComponent implements OnInit, OnDestroy {

  user: User;
  culturalInitiative: CulturalInitiative;
  userSubscription: Subscription;
  culturalInitiativesSubscription: Subscription;
  title: string;
  faEdit = faEdit;
  faTrash = faTrash;

  constructor(
    private culturalInitiativesService: CulturalInitiativesService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const subdomain = this.route.snapshot.paramMap.get('subdomain');
    this.culturalInitiativesService.get(subdomain);

    this.userSubscription = this.authService.user.subscribe(user => {
      if (user && !this.user) {
        this.user = user;
      }
    });

    this.culturalInitiativesSubscription = this.culturalInitiativesService.culturalInitiativeSubject.subscribe((data: CulturalInitiative[]) => {
      if (data.length > 0) {
        this.culturalInitiative = data[0];
        this.title = `ניהול ${this.culturalInitiative.name}`;
      }
    });
  }

  delete(): void {

  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.culturalInitiativesSubscription.unsubscribe();
  }
}
