<div class="sidenav bg-light-red" *ngIf="user">
  <div class="user-name-box pt-3 pb-3 pr-4 pl-4">
    <div>{{user.name}}</div>
    <div class="font-size-14">{{user.permissionTitle}}</div>
    <div class="pointer" (click)="logout()">התנתקות</div>
  </div>
  <div class="options-box">
    <!--<a class="pt-3 pb-3 pr-4 pl-4" routerLink="control-panel" *ngIf="user.manageSystem">לוח בקרה</a>-->
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="communities-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="user.manageSystem">קהילות</a>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="cultural-initiatives-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="user.manageSystem || user.manageCommunity">פסטיבלים</a>
    <a class="pt-3 pb-3 pr-4 pl-4" *ngIf="user.manageFestival && !user.manageCommunity" routerLink="cultural-initiative/{{user.adminHomePage}}" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">ניהול פסטיבל</a>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="initiatives-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">יוזמות</a>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="places-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">מקומות</a>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="users-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">חברי/ות קהילה</a>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="events-list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">אירועים</a>
    <!--<hr>
    <a class="pt-3 pb-3 pr-4 pl-4" routerLink="settings">הגדרות</a>-->
  </div>
</div>
