import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {faEdit, faEye, faFileExcel, faFilter, faLink, faSort, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ManageEvent} from '../shared/models/manage-event.model';
import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import {FilterEventsListPipe} from './filter-events-list.pipe';
import {FilterEventsListByObjectPipe} from './filter-events-list-by-object.pipe';
import {Festival} from '../shared/models/festival.model';
import {ManageDataService} from '../shared/services/manage-data.service';
import {AreaSettingModel, PublicModel} from '../shared/models/publics-area-settings.model';
import {Genre} from '../shared/models/genre.model';
import {Community} from '../shared/models/community.model';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';
import {ManageEventService} from '../shared/services/manage-event.service';
import {FileSaverService} from '../shared/services/file-saver.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './events-list.component.css'
  ]
})
export class EventsListComponent implements OnInit, OnDestroy {

  user: User;
  events: ManageEvent[];
  userSubscription: Subscription;
  eventsSubscription: Subscription;
  communitiesSubscription: Subscription;
  festivalsSubscription: Subscription;
  areaSettingsSubscription: Subscription;
  publicsSubscription: Subscription;
  categoriesSubscription: Subscription;
  deleteSubscription: Subscription;
  filterForm: FormGroup;
  communities: Community[];
  festivals: Festival[];
  publics: PublicModel[];
  areaSettings: AreaSettingModel[];
  categories: Genre[];
  virtualOptions: { value: boolean, title: string }[];
  totalText: string;
  key = 'eventID';
  reverse = false;
  search: string;
  filtered: boolean;
  numberOfFilteredResults: number;
  filterByObject: { communityID: number, festivalID: number, areaSetting: string, publicString: string, virtual: boolean, genre: string };
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faSort = faSort;
  faFilter = faFilter;
  faFileExcel = faFileExcel;
  faLink = faLink;

  constructor(
    private manageEventService: ManageEventService,
    private authService: AuthService,
    private manageDataService: ManageDataService,
    private filterByText: FilterEventsListPipe,
    private filterByObjectPipe: FilterEventsListByObjectPipe,
    private fileSaverService: FileSaverService
  ) {
  }

  ngOnInit(): void {
    this.getUser();
    this.getEvents();
    this.buildForm();
    this.getFormData();
  }

  getUser(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  getEvents(): void {
    this.eventsSubscription = this.manageEventService.get().subscribe(data => {
      this.events = data;
      const totalNumber = data.length;
      const text = 'אירועים';
      this.totalText = `${text} ${totalNumber}`;
    });
  }

  getFormData(): void {
    if (this.user.manageSystem || this.user.manageCommunity) {
      this.communitiesSubscription = this.manageDataService.getCommunities().subscribe(communities => {
        this.communities = communities;
      });
      this.festivalsSubscription = this.manageDataService.getFestivals().subscribe(festivals => {
        this.festivals = festivals;
      });
    }

    this.areaSettingsSubscription = this.manageDataService.getAreaSettings().subscribe(areaSettings => {
      this.areaSettings = areaSettings;
    });

    this.publicsSubscription = this.manageDataService.getPublics().subscribe(publics => {
      this.publics = publics;
    });

    this.categoriesSubscription = this.manageDataService.getGenres().subscribe(categories => {
      this.categories = categories;
    });

    this.virtualOptions = [
      {
        title: 'לא',
        value: false
      },
      {
        title: 'כן',
        value: true
      }
    ];
  }

  formatAsDay(date): string {
    return moment(date).locale('he').format('dd');
  }

  formatAsHour(date): string {
    return moment(date, 'h:mm:ss A').format('HH:mm');
  }

  buildForm(): void {
    this.filterForm = new FormGroup({
      communityID: new FormControl(''),
      festivalID: new FormControl(''),
      areaSetting: new FormControl(''),
      virtual: new FormControl(''),
      publicString: new FormControl(''),
      genre: new FormControl('')
    });
  }

  onFilter(): void {
    this.filtered = true;
    this.filterByObject = this.filterForm.value;
    if (!this.filterByObject.communityID && !this.filterByObject.festivalID && !this.filterByObject.areaSetting && !this.filterByObject.publicString && !this.filterByObject.genre) {
      this.clearFilter();
    } else {
      this.countFilteredResults();
    }
  }

  clearFilter(): void {
    this.filtered = false;
    this.filterForm.controls.communityID.setValue('');
    this.filterForm.controls.festivalID.setValue('');
    this.filterForm.controls.areaSetting.setValue('');
    this.filterForm.controls.publicString.setValue('');
    this.filterForm.controls.genre.setValue('');
    this.filterByObject = this.filterForm.value;
    this.numberOfFilteredResults = null;
  }

  countFilteredResults(): void {
    const filteredEvents = this.filterByObjectPipe.transform(this.events, this.filterForm.value);
    this.numberOfFilteredResults = filteredEvents.length;
  }

  countSearchResults(): void {
    const filteredResults = this.filterByText.transform(this.events, this.search);
    if (this.search === '') {
      this.numberOfFilteredResults = null;
    } else {
      this.numberOfFilteredResults = filteredResults.length;
    }
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  delete(id): void {
    const confirmDelete = confirm(`האם את/ה בטוח שאת/ה רוצה למחוק את אירוע מספר ${id}?`);
    if (confirmDelete) {
      this.deleteSubscription = this.manageEventService.delete(id).subscribe(res => {
        this.getEvents();
      });
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
    if (this.communitiesSubscription) {
      this.communitiesSubscription.unsubscribe();
    }
    if (this.festivalsSubscription) {
      this.festivalsSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    this.areaSettingsSubscription.unsubscribe();
    this.publicsSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
  }

  exportToExcel(): void {
    const fileName = 'Events List.xlsx';
    this.fileSaverService.exportExcel(fileName);
  }
}
