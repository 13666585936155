<div class="d-flex">
  <fa-icon class="color-turquoise pointer" [icon]="faFilter" (click)="showFilterDialog(filterModal)"></fa-icon>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">TITLE</h4>
  </div>
  <div class="modal-body">
    MODAL
  </div>
</ng-template>
