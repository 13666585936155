<div *ngIf="events">
  <app-list-title
    title="אירועים"
    image="https://api.hanadiv.org/assets/Icons_Events_Grey.svg"
    subTitle="{{totalText}}"
    filteredResults="{{numberOfFilteredResults}}">
  </app-list-title>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-space-between">
      <div class="d-flex w-100">
        <form class="d-flex" [formGroup]="filterForm">
          <label *ngIf="user.manageSystem || user.manageCommunity">
            <select formControlName="communityID" class="form-control" (change)="onFilter()">
              <option value="">בחירת קהילה</option>
              <option *ngFor="let item of communities" [ngValue]="item.communityID">{{item.title}}</option>
            </select>
          </label>
          <label *ngIf="user.manageSystem || user.manageCommunity">
            <select formControlName="festivalID" class="form-control" (change)="onFilter()">
              <option value="">בחירת פסטיבל</option>
              <option *ngFor="let item of festivals" [ngValue]="item.festivalID">{{item.name}}</option>
            </select>
          </label>
          <label>
            <select formControlName="publicString" class="form-control" (change)="onFilter()">
              <option value="">קהל יעד</option>
              <option *ngFor="let item of publics" [ngValue]="item.title">{{item.title}}</option>
            </select>
          </label>
          <label>
            <select formControlName="areaSetting" class="form-control" (change)="onFilter()">
              <option value="">בחירת סוג החלל</option>
              <option *ngFor="let item of areaSettings" [ngValue]="item.title">{{item.title}}</option>
            </select>
          </label>
          <label>
            <select formControlName="genre" class="form-control" (change)="onFilter()">
              <option value="">בחירת קטגוריה</option>
              <option *ngFor="let item of categories" [ngValue]="item.title">{{item.title}}</option>
            </select>
          </label>
          <label>
            <select formControlName="virtual" class="form-control" (change)="onFilter()">
              <option value="">אירוע וירטואלי</option>
              <option *ngFor="let item of virtualOptions" [ngValue]="item.value">{{item.title}}</option>
            </select>
          </label>
        </form>
        <fa-icon class="pointer font-size-20 ml-5 color-red" *ngIf="filtered" [icon]="faFilter" (click)="clearFilter()"></fa-icon>
        <div>
          <label>
            <input type="text" [(ngModel)]="search" class="form-control" (ngModelChange)="countSearchResults()">
          </label>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-2">
        <div>
          <button class="btn btn-success" (click)="exportToExcel()">
            <fa-icon [icon]="faFileExcel"></fa-icon>
          </button>
        </div>
      </div>

      <app-add-item-button addItemLink="/add-event"></app-add-item-button>
    </div>

    <table id="excel-table" class="table text-center">
      <tr>
        <td class="pointer" (click)="sort('eventID')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מס' מזהה</span>
        </td>
        <td class="pointer" (click)="sort('title')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>שם האירוע</span>
        </td>
        <td class="pointer" (click)="sort('initiativeOwnerName')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>יוזם</span>
        </td>
        <td>
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מקום/כתובת</span>
        </td>
        <td class="w-10 pointer" (click)="sort('eventFullTimeStamp')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מתי מתקיים</span>
        </td>
        <td class="pointer" (click)="sort('genre')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>קטגוריה</span>
        </td>
        <td class="w-10 pointer" (click)="sort('public')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>קהל היעד</span>
        </td>
        <td class="w-10 pointer" (click)="sort('virtual')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>וירטואלי</span>
        </td>
        <td class="w-10 pointer" (click)="sort('numberOfAttendees')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>נרשמים</span>
        </td>
        <td class="w-20">פעולות</td>
      </tr>
      <tr *ngFor="let item of events | filterEventsListByObject: filterByObject | filterEventsList:search | orderBy: key: reverse">
        <td>{{item.eventID}}</td>
        <td>{{item.title}}</td>
        <td>{{item.initiativeOwnerName}}</td>
        <td>
          <span>{{item.placeDescription}}</span>
          <br>
          <span *ngIf="item.street">{{item.street}}</span>
          <span *ngIf="item.houseNumber">{{item.houseNumber}}</span>
          <span *ngIf="item.entrance">{{item.entrance}}</span>
          <span *ngIf="item.city">, {{item.city}}</span>
        </td>
        <td>
          <span>{{formatAsDay(item.eventDate)}}',</span>
          <span> {{formatAsHour(item.eventHour)}}</span>
        </td>
        <td>{{item.genre}}</td>
        <td>{{item.public}}</td>
        <td>{{item.virtual ? 'כן' : 'לא'}}</td>
        <td>
          <span>{{item.numberOfAttendees}}</span>
          <span>/{{item.totalNumberOfPeopleThatCanAttend}}</span></td>
        <td class="d-flex justify-content-space-evenly">
          <button class="btn btn-warning">
            <a class="color-white" href="https://{{item.subdomain}}.hanadiv.org/event-page/{{item.eventID}}" target="_blank">
              <fa-icon [icon]="faLink"></fa-icon>
            </a>
          </button>
          <button class="btn btn-success" routerLink="/event/{{item.eventID}}">
            <fa-icon [icon]="faEye"></fa-icon>
          </button>
          <button class="btn btn-info" routerLink="/edit-event/{{item.eventID}}">
            <fa-icon [icon]="faEdit"></fa-icon>
          </button>
          <button class="btn btn-danger" (click)="delete(item.eventID)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
