export class User {

  constructor(
    public userID: number,
    public name: string,
    public email: string,
    public _token: string,
    public _tokenExpirationDate: Date,
    public authorizedByUserID: number,
    public manageCommunity: boolean,
    public manageFestival: boolean,
    public manageSystem: boolean,
    public permission: string,
    public permissionID: number,
    public adminHomePage: string
  ) {
  }

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    if (this._token) {
      return this._token;
    }
  }
}
