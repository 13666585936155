import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserDetails} from '../shared/models/user-details.model';
import {Subscription} from 'rxjs';
import {ManageUserService} from '../shared/services/manage-user.service';
import {ActivatedRoute} from '@angular/router';
import {faStar, faUsers, faAddressCard, faEnvelope, faPhone, faHome, faBuilding} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: [
    '../shared/styles/page.css',
    './user.component.css'
  ]
})
export class UserComponent implements OnInit, OnDestroy {

  user: UserDetails;
  userSubscription: Subscription;
  title: string;
  subTitle: string;
  faStar = faStar;
  faUsers = faUsers;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faHome = faHome;
  faBuilding = faBuilding;
  faAddressCard = faAddressCard;

  constructor(
    private userService: ManageUserService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const userID = this.route.snapshot.paramMap.get('userID');
    this.userSubscription = this.userService.get(userID).subscribe(data => {
      this.user = data;
      this.title = `${this.user.firstName} ${this.user.lastName}`;
      this.subTitle = this.user.city;
    });
  }

  getStatusTitle(statusID): void {
    let title;
    switch (statusID) {
      case 1:
        title = 'אושר';
        break;
    }
    return title;
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}
