import {Pipe, PipeTransform} from '@angular/core';
import {ManageUser} from '../shared/models/manage-user.model';

@Pipe({
  name: 'filterUsersList'
})
export class FilterUsersListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): ManageUser[] {
    if (!value || value.length === 0 || args.length === 0 || !args) {
      return;
    }
    let filteredObjects;
    filteredObjects = value.filter(item => {
      return (
        (item.userID.toString().includes(args)) ||
        (item.city && item.city.includes(args)) ||
        (item.email && item.email.includes(args)) ||
        (item.firstName && item.firstName.includes(args)) ||
        (item.lastName && item.lastName.includes(args)) ||
        (item.phone && item.phone.includes(args)) ||
        (item.status && item.status.includes(args)) ||
        (item.festivals && item.festivals.includes(args)) ||
        (item.communities && item.communities.includes(args)) ||
        (item.street && item.street.includes(args))
      );
    });
    return filteredObjects;
  }
}
