import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommunityService} from './community.service';
import {Community} from '../shared/models/community.model';
import {faEye, faEdit, faTrash, faSort} from '@fortawesome/free-solid-svg-icons';
import {User} from '../shared/models/user.model';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-communities-list',
  templateUrl: './communities-list.component.html',
  styleUrls: ['./communities-list.component.css']
})
export class CommunitiesListComponent implements OnInit, OnDestroy {

  user: User;
  userSubscription: Subscription;
  communities: Community[];
  communitiesSubscription: Subscription;
  totalText: string;
  numberOfFilteredResults: number;
  key = 'communityID';
  reverse = false;
  search: string;
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faSort = faSort;

  constructor(
    private router: Router,
    private authService: AuthService,
    private communityService: CommunityService
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
        if (!user.manageSystem) {
          this.router.navigate(['/']);
        }
      }
    });
    this.communitiesSubscription = this.communityService.get().subscribe(data => {
      this.communities = data;

      const text = 'סה"כ קהילות';
      this.totalText = `${text} ${this.communities.length}`;
    });
  }

  formatDate(date): string {
    return moment(date).format('HH:mm DD-MM-YYYY');
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  delete(id): void {
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.communitiesSubscription.unsubscribe();
  }

}
