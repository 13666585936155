import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterGenres'
})
export class FilterGenresPipe implements PipeTransform {
  transform(value: any, filterString: string): any {
    if (!value) {
      return;
    }
    if (value.length === 0) {
      return value;
    }
    const resultArray = [];
    for (const item of value) {
      if (item.title.includes(filterString)) {
        resultArray.push(item);
      }
    }
    return resultArray;
  }
}
