import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService {

  url = `${environment.apiURL}/initiatives`;

  constructor(private http: HttpClient) {
  }

  get(id = null): Observable<any> {
    let url = this.url;
    if (id) {
      url += `?initiativeID=${id}`;
    }
    return this.http.get(url);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.url}?initiativeID=${id}`);
  }

  update(data): Observable<any> {
    return this.http.put(`${environment.apiURL}/initiatives`, JSON.stringify(data));
  }
}
