import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {MainNavbarComponent} from './main-navbar/main-navbar.component';
import {CommunitiesListComponent} from './communities-list/communities-list.component';
import {PlacesListComponent} from './places-list/places-list.component';
import {InitiativesListComponent} from './initiatives-list/initiatives-list.component';
import {EventsListComponent} from './events-list/events-list.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoadingSpinnerComponent} from './shared/components/loading-spinner/loading-spinner.component';
import {EditInitiativeComponent} from './edit-initiative/edit-initiative.component';
import {EditPlaceComponent} from './edit-place/edit-place.component';
import {CulturalInitiativesListComponent} from './cultural-initiatives-list/cultural-initiatives-list.component';
import {CulturalInitiativeComponent} from './cultural-initiative/cultural-initiative.component';
import {EventComponent} from './event/event.component';
import {PlaceComponent} from './place/place.component';
import {InitiativeComponent} from './initiative/initiative.component';
import {EditCulturalInitiativeComponent} from './edit-cultural-initiative/edit-cultural-initiative.component';
import {UsersListComponent} from './users-list/users-list.component';
import {UserComponent} from './user/user.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {AuthService} from './auth/auth.service';
import {AuthGuardService} from './auth/auth-guard.service';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {EditCommunityComponent} from './edit-community/edit-community.component';
import {PageTitleComponent} from './page-title/page-title.component';
import {TableActionsComponent} from './table-actions/table-actions.component';
import {ListTitleComponent} from './list-title/list-title.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {EditItemButtonComponent} from './edit-item-button/edit-item-button.component';
import {AddItemButtonComponent} from './add-item-button/add-item-button.component';
import {AddEditButtonsComponent} from './add-edit-buttons/add-edit-buttons.component';
import {AddCulturalInitiativeComponent} from './add-cultural-initiative/add-cultural-initiative.component';
import {AddUserComponent} from './add-user/add-user.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {EditCulturalInitiativeAboutComponent} from './edit-cultural-initiative-about/edit-cultural-initiative-about.component';
import {EditCulturalInitiativeNavigationComponent} from './edit-cultural-initiative-navigation/edit-cultural-initiative-navigation.component';
import {EditCulturalInitiativeTeamComponent} from './edit-cultural-initiative-team/edit-cultural-initiative-team.component';
import {EditCulturalInitiativeThanksComponent} from './edit-cultural-initiative-thanks/edit-cultural-initiative-thanks.component';
import {EditCulturalInitiativeSleepComponent} from './edit-cultural-initiative-sleep/edit-cultural-initiative-sleep.component';
import {EditCulturalInitiativeUpdatesComponent} from './edit-cultural-initiative-updates/edit-cultural-initiative-updates.component';
import {FilterGenresPipe} from './edit-cultural-initiative/filter-genres.pipe';
import {AddCommunityComponent} from './add-community/add-community.component';
import {FilterUsersListPipe} from './users-list/filter-users-list.pipe';
import {FilterNeighborhoodsPipe} from './shared/pipes/filter-neighborhoods.pipe';
import {Ng2OrderModule} from 'ng2-order-pipe';
import {FilterCommunitiesListPipe} from './communities-list/filter-communities-list.pipe';
import {FilterCulturalInitiativesListPipe} from './cultural-initiatives-list/filter-cultural-initiatives-list.pipe';
import {FilterInitiativesListPipe} from './initiatives-list/filter-initiatives-list.pipe';
import {FilterPlacesListPipe} from './places-list/filter-places-list.pipe';
import {FilterEventsListPipe} from './events-list/filter-events-list.pipe';
import {FilterUsersListByObjectPipe} from './users-list/filter-users-list-by-object.pipe';
import {FilterCulturalInitiativesListByObjectPipe} from './cultural-initiatives-list/filter-cultural-initiatives-list-by-object.pipe';
import {FilterInitiativesListByObjectPipe} from './initiatives-list/filter-initiatives-list-by-object.pipe';
import {FilterPlacesListByObjectPipe} from './places-list/filter-places-list-by-object.pipe';
import {FilterEventsListByObjectPipe} from './events-list/filter-events-list-by-object.pipe';
import { AddEventComponent } from './add-event/add-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { EventFormComponent } from './event-form/event-form.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MainNavbarComponent,
    CommunitiesListComponent,
    EditCommunityComponent,
    PlacesListComponent,
    InitiativesListComponent,
    EventsListComponent,
    LoadingSpinnerComponent,
    EditInitiativeComponent,
    EditPlaceComponent,
    CulturalInitiativesListComponent,
    CulturalInitiativeComponent,
    EventComponent,
    PlaceComponent,
    InitiativeComponent,
    EditCulturalInitiativeComponent,
    UsersListComponent,
    UserComponent,
    EditUserComponent,
    PageTitleComponent,
    TableActionsComponent,
    ListTitleComponent,
    FileUploadComponent,
    EditItemButtonComponent,
    AddItemButtonComponent,
    AddEditButtonsComponent,
    AddCulturalInitiativeComponent,
    AddUserComponent,
    EditCulturalInitiativeAboutComponent,
    EditCulturalInitiativeNavigationComponent,
    EditCulturalInitiativeTeamComponent,
    EditCulturalInitiativeThanksComponent,
    EditCulturalInitiativeSleepComponent,
    EditCulturalInitiativeUpdatesComponent,
    FilterGenresPipe,
    FilterNeighborhoodsPipe,
    AddCommunityComponent,
    FilterUsersListPipe,
    FilterCommunitiesListPipe,
    FilterCulturalInitiativesListPipe,
    FilterInitiativesListPipe,
    FilterPlacesListPipe,
    FilterEventsListPipe,
    FilterUsersListByObjectPipe,
    FilterCulturalInitiativesListByObjectPipe,
    FilterInitiativesListByObjectPipe,
    FilterPlacesListByObjectPipe,
    FilterEventsListByObjectPipe,
    AddEventComponent,
    EditEventComponent,
    EventFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    NgxBootstrapSliderModule,
    AngularEditorModule,
    Ng2OrderModule
  ],
  providers: [
    AuthService,
    AuthGuardService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    FilterCulturalInitiativesListPipe,
    FilterCulturalInitiativesListByObjectPipe,
    FilterUsersListPipe,
    FilterUsersListByObjectPipe,
    FilterInitiativesListPipe,
    FilterInitiativesListByObjectPipe,
    FilterPlacesListPipe,
    FilterPlacesListByObjectPipe,
    FilterEventsListPipe,
    FilterEventsListByObjectPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
