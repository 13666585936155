import {Pipe, PipeTransform} from '@angular/core';
import {ManagePlace} from '../shared/models/manage-place.model';

@Pipe({
  name: 'filterPlacesList'
})
export class FilterPlacesListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): ManagePlace[] {
    if (!value || value.length === 0 || args.length === 0 || !args) {
      return;
    }
    let filteredObjects;
    filteredObjects = value.filter(item => {
      return (
        (item.placeID.toString().includes(args)) ||
        (item.areaSetting && item.areaSetting.includes(args)) ||
        (item.city && item.city.includes(args)) ||
        (item.description && item.description.includes(args)) ||
        (item.host && item.host.includes(args)) ||
        (item.neighborhood && item.neighborhood.includes(args)) ||
        (item.notes && item.notes.includes(args)) ||
        (item.street && item.street.includes(args))
      );
    });
    return filteredObjects;
  }
}
