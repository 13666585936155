<div class="container-fluid vh-100 mb-5">
  <div class="row page-navbar">
    <span class="col-12 text-center font-size-24 font-weight-500">הרשמה</span>
  </div>
  <div class="text-center" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="row mb-3" *ngIf="!isLoading">
    <div class="col-12 mt-3 intro-title">הצטרפו למאות היוצרים והיוצרות שמגשימים משאלות בפסטיבל</div>
    <div class="col-12 mt-3 more-text">כל הפרטים חשובים. בחיינו</div>
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="submitRegister()" *ngIf="!isLoading">
    <div class="form-group">
      <label>
        <input type="text" formControlName="firstName" class="form-control" placeholder="שם פרטי">
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.firstName.valid">נא להזין שם פרטי</div>
      <label>
        <input type="text" formControlName="lastName" class="form-control" placeholder="שם משפחה">
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.lastName.valid">נא להזין שם משפחה</div>
      <label>
        <input type="email" formControlName="email" class="form-control" placeholder="דואר אלקטרוני" autocomplete="off">
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.email.valid">נא להזין דואר אלקטרוני</div>
      <label>
        <input type="password" class="form-control" formControlName="password" placeholder="סיסמא">
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.password.valid">נא להזין סיסמא</div>
      <label>
        <input type="text" class="form-control" formControlName="phone" placeholder="נייד">
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && !registerForm.controls.phone.valid">נא להזין נייד</div>
      <label>
        <select class="form-control" formControlName="city">
          <option value="0">יישוב</option>
          <option *ngFor="let city of cities" [ngValue]="city.cityID">
            {{ city.title }}
          </option>
        </select>
      </label>
      <div class="color-red mb-1" *ngIf="formSubmitted && registerForm.controls.city.value==0">נא לבחור יישוב</div>
      <label>
        <input type="text" class="form-control" formControlName="street" placeholder="שם רחוב">
      </label>
      <label>
        <input type="number" class="form-control" formControlName="houseNumber" placeholder="מספר בית">
      </label>
    </div>
    <div class="text-center mb-5">
      <button class="btn btn-submit w-50 font-size-22" type="submit" [disabled]="requestInProgress">הרשמה</button>
      <div class="alert" [class.color-red]="error" [class.color-turquoise]="!error" *ngIf="serverMessage">{{serverMessage}}</div>
    </div>
  </form>
</div>
