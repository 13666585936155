<div *ngIf="initiatives">
  <app-list-title
    title="יוזמות"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    subTitle="{{totalText}}"
    filteredResults="{{numberOfFilteredResults}}">
  </app-list-title>
  <div class="container-fluid">
    <div class="d-flex">
      <form class="d-flex" [formGroup]="filterForm">
        <label class="filter-label" *ngIf="user.manageSystem || user.manageCommunity">
          <select formControlName="festivalID" class="form-control" (change)="onFilter()">
            <option value="">בחירת פסטיבל</option>
            <option *ngFor="let item of festivals" [ngValue]="item.festivalID">{{item.name}}</option>
          </select>
        </label>
        <label class="filter-label">
          <select formControlName="matchedWithPlace" class="form-control" (change)="onFilter()">
            <option value="">האם משודך למקום</option>
            <option *ngFor="let item of matchedWithPlaceOptions" [ngValue]="item.value">{{item.title}}</option>
          </select>
        </label>
        <label class="filter-label">
          <select formControlName="category" class="form-control" (change)="onFilter()">
            <option value="">בחירת קטגוריה</option>
            <option *ngFor="let item of categories" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </label>
        <label class="filter-label">
          <select formControlName="publicValue" class="form-control" (change)="onFilter()">
            <option value="">בחירת קהל יעד</option>
            <option *ngFor="let item of publics" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </label>
      </form>
      <fa-icon class="pointer font-size-20 ml-5 color-red" *ngIf="filtered" [icon]="faFilter" (click)="clearFilter()"></fa-icon>
      <div class="d-flex">
        <label>
          <input type="text" [(ngModel)]="search" class="form-control" (ngModelChange)="countSearchResults()">
        </label>
      </div>
    </div>

    <table class="table text-center">
      <tr>
        <td class="pointer" (click)="sort('initiativeID')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מס' מזהה</span>
        </td>
        <td class="w-15 pointer" (click)="sort('title')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>שם היוזמה</span>
        </td>
        <td class="pointer" (click)="sort('initiativeOwnerName')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>יוזם/ת</span>
        </td>
        <td class="pointer" (click)="sort('existingEventWithInitiativeStatus')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>משודך למקום</span>
        </td>
        <td class="pointer" (click)="sort('genre')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>קטגוריה</span>
        </td>
        <td class="pointer" (click)="sort('public')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>קהל יעד</span>
        </td>
        <td>פעולות</td>
      </tr>
      <tr *ngFor="let item of initiatives | filterInitiativesListByObject: filterByObject | filterInitiativesList:search | orderBy: key: reverse ">
        <td>{{item.initiativeID}}</td>
        <td>
          <span class="font-weight-bold">{{item.title}}</span>
          <br>
          <span>{{item.description}}</span>
        </td>
        <td>{{item.initiativeOwnerName}}</td>
        <td>
          <span *ngIf="item.existingEventWithInitiativeStatus" class="text-success font-weight-bold">כן</span>
          <span *ngIf="!item.existingEventWithInitiativeStatus" class="text-danger font-weight-bold">לא</span>
        </td>
        <td>{{item.genre}}</td>
        <td>{{item.public}}</td>
        <td class="d-flex justify-content-space-evenly">
          <button class="btn btn-success" routerLink="/initiative/{{item.initiativeID}}">
            <fa-icon [icon]="faEye"></fa-icon>
          </button>
          <button class="btn btn-info" routerLink="/edit-initiative/{{item.initiativeID}}">
            <fa-icon [icon]="faEdit"></fa-icon>
          </button>
          <button class="btn btn-danger" (click)="delete(item.initiativeID)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
