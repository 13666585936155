<div class="container-fluid mb-7" *ngIf="initiative">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{initiative.initiativeID}}"
    createDate="{{initiative.initiativeCreateTimeStamp}}"></app-page-title>
  <form [formGroup]="editInitiativeForm" (ngSubmit)="onSubmit()">
    <!-- step 1 -->
    <div>
      <label class="mt-3">
        <span>שם היוזמה</span>
        <input type="text" class="form-control" placeholder="שם היוזמה" formControlName="title">
      </label>
      <label class="mt-3">
        <span>תיאור היוזמה</span>
        <input type="text" class="form-control" placeholder="תיאור היוזמה" formControlName="description">
      </label>
      <label class="mt-3">
        <span>פירט היוזמה</span>
        <textarea type="text" class="form-control" placeholder="פירוט היוזמה" formControlName="details"></textarea>
      </label>
      <label>
        <span>משך היוזמה בדקות</span>
      </label>
      <div class="duration-box">
        <div>
          <fa-icon [icon]="faPlusCircle" (click)="addDuration()"></fa-icon>
        </div>
        <div class="duration form-control text-center w-50">{{this.editInitiativeForm.controls.duration.value}}</div>
        <div>
          <fa-icon [icon]="faMinusCircle" (click)="decreaseDuration()"></fa-icon>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="dynamicFormData">
        <div>כמה אנשים יכולים להשתתף בפעילות?</div>
        <mv-slider [(value)]="initiative.limitNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        <div>{{initiative.limitNumberOfPeople}}</div>
      </div>
      <div class="form-group mt-4" *ngIf="dynamicFormData">
        <div class="group-title mt-3 mr-3">קהל יעד</div>
        <label *ngFor="let item of dynamicFormData.publics" class="group-item mt-2 mr-3 pb-2">
          <input type="radio" value="{{item.value}}" class="border-greyish" name="publicValue" formControlName="publicValue">
          <span class="pr-2">{{item.title}}</span>
        </label>
      </div>
      <div class="form-group mt-4" *ngIf="dynamicFormData">
        <div class="group-title mt-3 mr-3">לאן היוזמה מתאימה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings" class="group-item mt-2 mr-3 pb-2">
          <input type="radio" value="{{item.value}}" class="border-greyish" name="areaSettingValue" formControlName="areaSettingValue">
          <span class="pr-2">{{item.title}}</span>
        </label>
      </div>
    </div>

    <!-- step 2 -->
    <div>
      <div>בחירת ז'אנר</div>
      <label>
        <select class="form-control" formControlName="genreID">
          <option value="0">קטגוריה</option>
          <option *ngFor="let item of genres" [ngValue]="item.genreID">
            {{ item.title }}
          </option>
        </select>
      </label>
    </div>

    <!-- step 3 -->
    <div>
      <div class="container-fluid h-100 festivals-box">
        <div class="mt-4 mb-4 row col-12 font-size-17">האם יש עוד משתתפים ביוזמה?</div>
        <div class="more-participants-box">
          <div class="row" (click)="open(editParticipantModal)">
            <div class="col">משתתפים</div>
            <img id="plus-icon" src="https://api.hanadiv.org/assets/Continue.svg" alt="הוספת משתתף או משתתפת">
          </div>
          <hr class="color-light-grey">
          <div *ngIf="!participantsList || participantsList.length===0" class="row col font-size-12 color-greyish">לא נוספו משתתפים</div>
          <div *ngFor="let item of participantsList; let i = index">
            <span class="font-size-17 font-weight-bold">{{item.firstName}} </span>
            <span class="font-size-17 font-weight-bold">{{item.lastName}} </span>
            <span>| {{item.role}}</span>
            <span class="float-left">
                <fa-icon [icon]="faTrash" (click)="removeUser(i)"></fa-icon>
              </span>
          </div>
        </div>
        <label class="mt-3">
          <input type="text" class="form-control" placeholder="שם ההרכב/קבוצה" formControlName="groupTitle" (change)="resetAddGroupNameError(false)">
        </label>
        <div *ngIf="showAddGroupNameError" class="">שכחתם לציין איך קוראים לכם?</div>
        <label class="mt-3">
          <input type="text" class="form-control" placeholder="אתר (לא חובה)" formControlName="website">
        </label>
        <label class="mt-3">
          <input type="text" class="form-control" placeholder="קישור ל Live Stream" formControlName="liveStreamURL">
        </label>
      </div>

      <ng-template #editParticipantModal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">הוספת משתתף</h4>
        </div>
        <div class="modal-body">
          <form [formGroup]="editInitiativeMoreParticipantsForm" (ngSubmit)="onSubmitAddParticipantForm(modal);">
            <label class="mt-3">
              <input type="text" class="form-control" placeholder="שם פרטי" formControlName="firstName">
            </label>
            <label class="mt-3">
              <input type="text" class="form-control" placeholder="שם משפחה" formControlName="lastName">
            </label>
            <label class="mt-3">
              <input type="text" class="form-control" placeholder="תפקיד" formControlName="role">
            </label>
            <div class="modal-footer mt-3">
              <button class="col btn btn-dismiss font-size-22" type="button" (click)="modal.close()">ביטול</button>
              <button class="col btn btn-submit font-size-22" type="submit">הוספה</button>
            </div>
          </form>
        </div>
      </ng-template>
    </div>

    <!-- step 4 -->
    <div>
      <div class="container-fluid h-100">
        <app-file-upload title="תמונה של היוזמה" userID="{{initiative.ownerID}}" subdomain="{{initiative.festivalEnglishName}}"></app-file-upload>
        <div class="col-10 m-auto" *ngIf="imageSource">
          <img [src]="imageSource" class="image rounded img-thumbnail" alt="תמונת האירוע">
        </div>
      </div>
    </div>

    <!-- step 5 -->
    <div>
      <div>
        <div>
          <form [formGroup]="editInitiativeTagsForm" (ngSubmit)="onAddTag()" class="w-100">
            <label class="w-100 mt-3">
              <input type="text" class="form-control" placeholder="הזינו תגיות (לדוגמא רוק, פסנתר)" formControlName="tag">
            </label>
          </form>
        </div>
        <!--<div *ngFor="let item of tags | filterTags:editInitiativeTagsForm.value.tag" (click)="selectTag(item)">
          <div *ngIf="editInitiativeTagsForm.value.tag.length>0 && showItem(item.title)">{{item.title}}</div>
        </div>-->
        <hr class="color-light-grey">
        <div *ngIf="!tagsList || tagsList.length===0" class="row col font-size-12 color-greyish">לא נוספו תגיות</div>
        <div *ngFor="let item of tagsList; let i = index">
          <span class="font-size-17 font-weight-bold">{{item.title}} </span>
          <span class="float-left">
              <fa-icon [icon]="faTrash" (click)="removeTag(i)"></fa-icon>
            </span>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-submit font-size-18 pr-3 pl-3 w-75" type="submit">עדכון פרטי היוזמה</button>
    </div>
  </form>
  <div class="text-center mt-5 mb-10">
    <button class="btn btn-delete font-size-18 pr-3 pl-3 w-75" (click)="delete()" type="button">
      <fa-icon [icon]="faTrash" class="float-right"></fa-icon>
      <span>מחיקה</span>
    </button>
  </div>
</div>
