import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CulturalInitiativesService} from '../shared/services/cultural-initiatives-service.service';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';
import {faEye, faEdit, faTrash, faSort, faFilter} from '@fortawesome/free-solid-svg-icons';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import {ManageDataService} from '../shared/services/manage-data.service';
import {Community} from '../shared/models/community.model';
import {City} from '../shared/models/city.model';
import {FilterCulturalInitiativesListByObjectPipe} from './filter-cultural-initiatives-list-by-object.pipe';
import {FilterCulturalInitiativesListPipe} from './filter-cultural-initiatives-list.pipe';

@Component({
  selector: 'app-cultural-initiatives-list',
  templateUrl: './cultural-initiatives-list.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './cultural-initiatives-list.component.css'
  ]
})
export class CulturalInitiativesListComponent implements OnInit, OnDestroy {

  user: User;
  userSubscription: Subscription;
  culturalInitiatives: CulturalInitiative[];
  culturalInitiativesSubscription: Subscription;
  communitiesSubscription: Subscription;
  citiesSubscription: Subscription;
  statusesSubscription: Subscription;
  filterForm: FormGroup;
  communities: Community[];
  cities: City[];
  statuses: { title: string, value: boolean }[];
  title = 'רשימת פסטיבלים';
  subTitle;
  totalText;
  key = 'festivalID';
  reverse = false;
  search: string;
  filtered: boolean;
  numberOfFilteredResults: number;
  filterByObject: { communityID: number, city: string, archived: string };
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faSort = faSort;
  faFilter = faFilter;

  constructor(
    private router: Router,
    private authService: AuthService,
    private manageDataService: ManageDataService,
    private culturalInitiativesService: CulturalInitiativesService,
    private filterByText: FilterCulturalInitiativesListPipe,
    private filterByObjectPipe: FilterCulturalInitiativesListByObjectPipe,
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user && !this.user) {
        this.user = user;
        /*if (!user.manageCommunity) {
          this.router.navigate(['/login']);
        }*/
      }
    });

    this.buildForm();

    this.culturalInitiativesService.get();

    this.culturalInitiativesSubscription = this.culturalInitiativesService.culturalInitiativeSubject.subscribe((data: CulturalInitiative[]) => {
      this.culturalInitiatives = data;
      const text = 'פסטיבלים';
      this.totalText = `${text} ${data.length}`;
    });

    this.communitiesSubscription = this.manageDataService.getCommunities().subscribe(communities => {
      this.communities = communities;
    });

    this.citiesSubscription = this.manageDataService.getCities().subscribe(cities => {
      this.cities = cities;
    });

    this.statuses = [
      {title: 'לא פעיל', value: true},
      {title: 'פעיל', value: false},
    ];
  }

  buildForm(): void {
    this.filterForm = new FormGroup({
      communityID: new FormControl(''),
      city: new FormControl(''),
      archived: new FormControl(''),
    });
  }

  formatFromDate(date): string {
    return moment(date).format('DD-MM');
  }

  formatUntilDate(date): string {
    return moment(date).format('DD-MM-YYYY');
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  onFilter(): void {
    this.filtered = true;
    this.filterByObject = this.filterForm.value;
    if (!this.filterByObject.communityID && !this.filterByObject.city && !this.filterByObject.archived) {
      this.clearFilter();
    } else {
      this.countFilteredResults();
    }
  }

  clearFilter(): void {
    this.filtered = false;
    this.filterForm.controls.communityID.setValue('');
    this.filterForm.controls.city.setValue('');
    this.filterForm.controls.archived.setValue('');
    this.filterByObject = this.filterForm.value;
    this.numberOfFilteredResults = null;
  }

  countFilteredResults(): void {
    const filteredCulturalInitiatives = this.filterByObjectPipe.transform(this.culturalInitiatives, this.filterForm.value);
    this.numberOfFilteredResults = filteredCulturalInitiatives.length;
  }

  countSearchResults(): void {
    const filteredResults = this.filterByText.transform(this.culturalInitiatives, this.search);
    if (this.search === '') {
      this.numberOfFilteredResults = null;
    } else {
      this.numberOfFilteredResults = filteredResults.length;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.culturalInitiativesSubscription.unsubscribe();
    this.communitiesSubscription.unsubscribe();
    this.citiesSubscription.unsubscribe();
  }

}
