import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-add-edit-buttons',
  templateUrl: './add-edit-buttons.component.html',
  styleUrls: ['./add-edit-buttons.component.css']
})
export class AddEditButtonsComponent implements OnInit {

  @Input() addLink: string;
  @Input() editLink: string;
  constructor() { }

  ngOnInit(): void {
  }

}
