import {Pipe, PipeTransform} from '@angular/core';
import {ManageInitiative} from '../shared/models/manage-initiative.model';

@Pipe({
  name: 'filterInitiativesListByObject'
})
export class FilterInitiativesListByObjectPipe implements PipeTransform {

  transform(value: any, ...args: { festivalID: number, matchedWithPlace: number, category: string, publicValue: string }[]): ManageInitiative[] {
    if (!value || value.length === 0) {
      return;
    }
    let resultArray;
    let filteredObjects = [];
    let festivalID;
    let matchedWithPlace;
    let category;
    let publicValue;

    if (args[0]) {
      festivalID = args[0].festivalID;
      matchedWithPlace = args[0].matchedWithPlace.toString();
      category = args[0].category;
      publicValue = args[0].publicValue;
    }

    if (args[0]) {
      filteredObjects = value;
      if (festivalID) {
        filteredObjects = filteredObjects.filter(item => item.festivalID.toString() === festivalID);
      }
      if (matchedWithPlace) {
        filteredObjects = filteredObjects.filter(item => item.existingEventWithInitiativeStatus.toString() === matchedWithPlace);
      }
      if (category) {
        filteredObjects = filteredObjects.filter(item => item.genre === category);
      }
      if (publicValue) {
        filteredObjects = filteredObjects.filter(item => item.public === publicValue);
      }
      resultArray = filteredObjects;
    } else {
      resultArray = value;
    }
    return resultArray;
  }

}
