import {Component, OnInit} from '@angular/core';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.css']
})
export class TableActionsComponent implements OnInit {

  faFilter = faFilter;

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
  }

  showFilterDialog(modal): void {
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

}
