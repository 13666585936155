import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageEventService {

  url = `${environment.apiURL}/events`;

  constructor(private http: HttpClient) {
  }

  get(id = null): Observable<any> {
    let url = `${environment.apiURL}/events`;
    if (id) {
      url += `?eventID=${id}`;
    }
    return this.http.get(url);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.url}?eventID=${id}`);
  }

  createEvent(festivalEnglishName, data): Observable<any> {
    return this.http.post(`${this.url}?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  updateEvent(festivalEnglishName, data): Observable<any> {
    return this.http.put(`${this.url}?festival=${festivalEnglishName}`, JSON.stringify(data));
  }
}
