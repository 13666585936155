import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ManageUserService} from '../shared/services/manage-user.service';
import {faEye, faEdit, faTrash, faFilter, faSort} from '@fortawesome/free-solid-svg-icons';
import {ManageUser} from '../shared/models/manage-user.model';
import {FormControl, FormGroup} from '@angular/forms';
import {City} from '../shared/models/city.model';
import {ManageDataService} from '../shared/services/manage-data.service';
import {Festival} from '../shared/models/festival.model';
import {UserStatus} from '../shared/models/user-status.model';
import {FilterUsersListByObjectPipe} from './filter-users-list-by-object.pipe';
import {FilterUsersListPipe} from './filter-users-list.pipe';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './users-list.component.css'
  ]
})
export class UsersListComponent implements OnInit, OnDestroy {

  user: User;
  users: ManageUser[];
  userSubscription: Subscription;
  usersSubscription: Subscription;
  filterForm: FormGroup;
  festivalsSubscription: Subscription;
  citiesSubscription: Subscription;
  statusesSubscription: Subscription;
  festivals: Festival[];
  cities: City[];
  statuses: UserStatus[];
  totalText: string;
  filterByObject: { festival: string, city: string, status: string };
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faFilter = faFilter;
  faSort = faSort;
  search: string;
  key = 'userID';
  reverse = false;
  filtered: boolean;
  numberOfFilteredResults: number;

  constructor(
    private manageUserService: ManageUserService,
    private authService: AuthService,
    private manageDataService: ManageDataService,
    private filterByText: FilterUsersListPipe,
    private filterUsersListByObjectPipe: FilterUsersListByObjectPipe
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    this.usersSubscription = this.manageUserService.get().subscribe(data => {
      this.users = data;
      const totalNumber = data.length;
      const text = 'חברים/ות';
      this.totalText = `${text} ${totalNumber}`;
    });

    this.buildFilterArrays();

    this.buildForm();
  }

  buildFilterArrays(): void {
    if (this.user.manageSystem || this.user.manageCommunity) {
      this.festivalsSubscription = this.manageDataService.getFestivals().subscribe(festivals => {
        this.festivals = festivals;
      });
    }

    this.citiesSubscription = this.manageDataService.getCities().subscribe(cities => {
      this.cities = cities;
    });

    this.statusesSubscription = this.manageDataService.getUsersStatuses().subscribe(statuses => {
      this.statuses = statuses;
    });

  }

  buildForm(): void {
    this.filterForm = new FormGroup({
      festival: new FormControl(''),
      city: new FormControl(''),
      status: new FormControl(''),
    });
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  onFilter(): void {
    this.filtered = true;
    this.filterByObject = this.filterForm.value;

    if (!this.filterByObject.festival && !this.filterByObject.city && !this.filterByObject.status) {
      this.clearFilter();
    } else {
      this.countFilteredResults();
    }
  }

  clearFilter(): void {
    this.filtered = false;
    this.filterForm.controls.festival.setValue('');
    this.filterForm.controls.city.setValue('');
    this.filterForm.controls.status.setValue('');
    this.filterByObject = this.filterForm.value;
    this.numberOfFilteredResults = null;
  }

  countFilteredResults(): void {
    const filteredUsersList = this.filterUsersListByObjectPipe.transform(this.users, this.filterForm.value);
    this.numberOfFilteredResults = filteredUsersList.length;
  }

  countSearchResults(): void {
    const filteredResults = this.filterByText.transform(this.users, this.search);
    if (this.search === '') {
      this.numberOfFilteredResults = null;
    } else {
      this.numberOfFilteredResults = filteredResults.length;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
    if (this.festivalsSubscription) {
      this.festivalsSubscription.unsubscribe();
    }
    if (this.citiesSubscription) {
      this.citiesSubscription.unsubscribe();
    }
    if (this.statusesSubscription) {
      this.statusesSubscription.unsubscribe();
    }
  }
}
