<div class="mb-4">
  <div class="page-title color-turquoise font-size-24">
    <img src="{{image}}" class="image ml-2" alt="">
    <span>{{title}}</span>
  </div>
  <div class="color-turquoise">{{subTitle}}</div>
  <div class="color-red" *ngIf="filteredResults">
    <span>תוצאות סינון: </span>
    <span>{{filteredResults}}</span>
  </div>
</div>
