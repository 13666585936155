import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManageInitiative} from '../shared/models/manage-initiative.model';
import {Subscription} from 'rxjs';
import {InitiativeService} from '../shared/services/initiative-service.service';
import {ActivatedRoute} from '@angular/router';
import {faAddressCard, faUsers, faEdit} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-initiative',
  templateUrl: './initiative.component.html',
  styleUrls: [
    '../shared/styles/page.css',
    './initiative.component.css'
  ]
})
export class InitiativeComponent implements OnInit, OnDestroy {

  initiative: ManageInitiative;
  initiativeSubscription: Subscription;
  title: string;
  subTitle: string;
  faAddressCard = faAddressCard;
  faUsers = faUsers;

  constructor(
    private initiativeService: InitiativeService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const initiativeID = this.route.snapshot.paramMap.get('initiativeID');
    this.initiativeSubscription = this.initiativeService.get(initiativeID).subscribe(data => {
      this.initiative = data[0];
      this.title = this.initiative.title;
      this.subTitle = this.initiative.description;
    });
  }

  ngOnDestroy(): void {
    this.initiativeSubscription.unsubscribe();
  }
}
