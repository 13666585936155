import {Pipe, PipeTransform} from '@angular/core';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';

@Pipe({
  name: 'filterCulturalInitiativesListByObject'
})
export class FilterCulturalInitiativesListByObjectPipe implements PipeTransform {

  transform(value: any, ...args: { communityID: number, city: string, archived: string }[]): CulturalInitiative[] {
    if (!value || value.length === 0) {
      return;
    }
    let resultArray;
    let filteredObjects = [];
    let communityID;
    let city;
    let archived;

    if (args[0]) {
      communityID = args[0].communityID;
      city = args[0].city;
      archived = args[0].archived.toString();
    }

    if (args[0]) {
      filteredObjects = value;
      if (communityID) {
        filteredObjects = value.filter(item => item.communityID === communityID);
      }
      if (city) {
        filteredObjects = filteredObjects.filter(item => item.city === city);
      }
      if (archived) {
        filteredObjects = filteredObjects.filter(item => item.archived.toString() === archived);
      }
      resultArray = filteredObjects;
    } else {
      resultArray = value;
    }
    return resultArray;
  }

}
