import {Pipe, PipeTransform} from '@angular/core';
import {ManagePlace} from '../shared/models/manage-place.model';
import {parse} from '@fortawesome/fontawesome-svg-core';

@Pipe({
  name: 'filterPlacesListByObject'
})
export class FilterPlacesListByObjectPipe implements PipeTransform {

  transform(value: any, ...args: { festivalID: number, matchedWithInitiative: number, areaSetting: string }[]): ManagePlace[] {
    if (!value || value.length === 0) {
      return;
    }
    let resultArray;
    let filteredObjects = [];
    let festivalID;
    let matchedWithInitiative;
    let areaSetting;

    if (args[0]) {
      festivalID = args[0].festivalID;
      matchedWithInitiative = args[0].matchedWithInitiative.toString();
      areaSetting = args[0].areaSetting;
    }

    if (args[0]) {
      filteredObjects = value;
      if (festivalID) {
        filteredObjects = value.filter(item => {
          return item.festivalID === parseInt(festivalID, 10);
        });
      }
      if (matchedWithInitiative) {
        filteredObjects = filteredObjects.filter(item => {
          if (matchedWithInitiative === '1') {
            return item.numberOfEventsAtPlace > 0;
          }
          return item.numberOfEventsAtPlace === 0;
        });
      }
      if (areaSetting) {
        filteredObjects = filteredObjects.filter(item => item.areaSetting === areaSetting);
      }
      resultArray = filteredObjects;
    } else {
      resultArray = value;
    }
    return resultArray;
  }

}
