<div *ngIf="initiative">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{initiative.initiativeID}}"
    createDate="{{initiative.initiativeCreateTimeStamp}}"></app-page-title>
  <app-edit-item-button editItemLink="/edit-initiative/{{initiative.initiativeID}}"></app-edit-item-button>
  <div class="container-fluid main-box">
    <div class="row">
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">מקום</div>
          <div class="col-12 data-value" *ngIf="initiative.matchedPlaceID">{{initiative.street}} {{initiative.houseNumber}} {{initiative.entrance}} {{initiative.neighborhood}}, {{initiative.city}}</div>
          <div class="col-12 text-danger" *ngIf="!initiative.matchedPlaceID && !initiative.virtual">לא משוייך למקום</div>
          <div class="col-12 text-success" *ngIf="initiative.virtual">וירטואלי</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">קטגוריה</div>
          <div class="col-12 data-value">{{initiative.genre}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-boldborder-bottom">תגיות</div>
          <div class="tag" *ngFor="let item of initiative.tags">{{item.title}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">שם ההרכב / יוצר / מנחה (יופיע בתוכנייה)</div>
          <div class="col-12 data-value">{{initiative.groupTitle}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">פירוט ההרכב - אם קיים (יופיע בתוכנייה)</div>
          <div class="col-12 data-value" *ngFor="let item of initiative.participants">
            {{item.firstName}} {{item.lastName}} | {{item.role}}
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faAddressCard"></fa-icon>
            <span>אנשי קשר</span>
          </div>
          <div class="col-12 data-value">
            {{initiative.firstName}} {{initiative.lastName}} {{initiative.phone}}
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>כמה אנשים יכולים להשתתף</span>
          </div>
          <div class="col-12 data-value">{{initiative.limitNumberOfPeople}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">קהל היעד</div>
          <div class="col-12 data-value">{{initiative.public}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">תיאור היוזמה (יופיע בתוכנייה)</div>
          <div class="col-12 data-value">{{initiative.description}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">סוג החלל</div>
          <div class="col-12 data-value">{{initiative.area}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">תמונות לפירסום האירוע</div>
          <img *ngIf="initiative.path" src="{{initiative.path}}" class="image img-thumbnail" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
