<div class="container-fluid" *ngIf="culturalInitiative">
  <app-page-title
    title="{{title}}"
    image="https://api.hanadiv.org/images/{{culturalInitiative.logo}}"
    rowID="{{culturalInitiative.festivalID}}"
    createDate="{{culturalInitiative.insertTimeStamp}}"
  ></app-page-title>
  <app-edit-item-button editItemLink="/edit-cultural-initiative/{{culturalInitiative.subdomain}}"></app-edit-item-button>
  <div class="container-fluid main-box">
    <div class="row">
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">חברים בפסטיבל</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">יוזמות</div>
          <div class="col-12 data-value">{{culturalInitiative.initiatives.total}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">מקומות</div>
          <div class="col-12 data-value">{{culturalInitiative.places.total}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">אירועים</div>
          <div class="col-12 data-value">{{culturalInitiative.events.total}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">תאריכים ושעות</div>
          <div class="col-12 data-value">{{culturalInitiative.fromDate}}</div>
          <div class="col-12 data-value">{{culturalInitiative.untilDate}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">סטטוס</div>
          <div class="col-12 data-value">{{culturalInitiative.archived === 'true' ? 'לא פעיל' : 'פעיל'}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">יישוב</div>
          <div class="col-12 data-value">{{culturalInitiative.city}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">לוגו</div>
          <img *ngIf="culturalInitiative.logo" src="https://api.hanadiv.org/images/{{culturalInitiative.logo}}" class="image img-thumbnail" alt="">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="data-box">
        <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">אודות</div>
        <div class="col-12 data-value">{{culturalInitiative.about}}</div>
      </div>
    </div>
  </div>
</div>


<!--<div class="row">
  <button class="btn btn-info" routerLink="/edit-cultural-initiative/{{culturalInitiative.subdomain}}">
    <fa-icon [icon]="faEdit"></fa-icon>
  </button>
</div>-->
