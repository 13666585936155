<div class="edit-community-box">
  <app-list-title title="עריכת קהילה" image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"></app-list-title>
  <form [formGroup]="editCommunityForm" (ngSubmit)="onSubmit()">
    <label>
      <span>שם הקהילה</span>
      <input type="text" formControlName="title" class="form-control">
    </label>
    <div class="text-center mt-5">
      <button class="btn btn-submit font-size-18 pr-3 pl-3" type="submit">שמירה</button>
    </div>
  </form>
</div>
