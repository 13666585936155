import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ManageEvent} from '../shared/models/manage-event.model';
import {ManageEventService} from '../shared/services/manage-event.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit, OnDestroy {

  eventDataSubscription: Subscription;
  eventData: ManageEvent;

  constructor(
    private eventService: ManageEventService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const eventID = this.route.snapshot.paramMap.get('eventID');
    this.eventDataSubscription = this.eventService.get(eventID).subscribe(eventData => {
      this.eventData = eventData;
    });
  }

  ngOnDestroy(): void {
    this.eventDataSubscription.unsubscribe();
  }
}
