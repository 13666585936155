import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunityService} from '../communities-list/community.service';
import {Subscription} from 'rxjs';
import {Community} from '../shared/models/community.model';

@Component({
  selector: 'app-edit-community',
  templateUrl: './edit-community.component.html',
  styleUrls: ['./edit-community.component.css']
})
export class EditCommunityComponent implements OnInit {

  editCommunityForm: FormGroup;
  communitySubscription: Subscription;
  community: Community;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService
  ) {
  }

  ngOnInit(): void {
    const communityID = this.route.snapshot.paramMap.get('communityID');

    this.communitySubscription = this.communityService.get(communityID).subscribe(data => {
      this.community = data;
      this.editCommunityForm.controls.communityID.setValue(this.community.communityID);
      this.editCommunityForm.controls.title.setValue(this.community.title);
    });

    this.editCommunityForm = new FormGroup({
      communityID: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required])
    });
  }

  onSubmit(): void {
    if (this.editCommunityForm.status === 'VALID') {
      this.communityService.update(this.editCommunityForm.value).subscribe(res => {
        this.router.navigate(['/communities-list']);
      });
    }
  }


}
