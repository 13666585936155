import {Pipe, PipeTransform} from '@angular/core';
import {ManageEvent} from '../shared/models/manage-event.model';

@Pipe({
  name: 'filterEventsListByObject'
})
export class FilterEventsListByObjectPipe implements PipeTransform {

  transform(value: any, ...args: { communityID: number, festivalID: number, areaSetting: string, publicString: string, virtual: boolean, genre: string }[]): ManageEvent[] {
    if (!value || value.length === 0) {
      return;
    }
    let resultArray;
    let filteredObjects = [];
    let communityID;
    let festivalID;
    let areaSetting;
    let publicString;
    let virtual;
    let genre;

    if (args[0]) {
      communityID = args[0].communityID;
      festivalID = args[0].festivalID;
      areaSetting = args[0].areaSetting;
      publicString = args[0].publicString;
      virtual = args[0].virtual;
      genre = args[0].genre;
    }

    if (args[0]) {
      filteredObjects = value;
      if (communityID) {
        filteredObjects = value.filter(item => {
          return item.communityID === parseInt(communityID, 10);
        });
      }
      if (festivalID) {
        filteredObjects = value.filter(item => {
          return item.festivalID === parseInt(festivalID, 10);
        });
      }
      if (areaSetting) {
        filteredObjects = filteredObjects.filter(item => item.areaSetting === areaSetting);
      }
      if (publicString) {
        filteredObjects = filteredObjects.filter(item => item.public === publicString);
      }
      if (genre) {
        filteredObjects = filteredObjects.filter(item => item.genre === genre);
      }
      if (virtual !== undefined && virtual !== '') {
        filteredObjects = filteredObjects.filter(item => item.virtual === virtual);
      }
      resultArray = filteredObjects;
    } else {
      resultArray = value;
    }
    return resultArray;
  }

}
