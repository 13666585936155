import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../shared/models/user.model';
import {Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css']
})
export class MainNavbarComponent implements OnInit, OnDestroy {

  user: User;
  userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  logout(): void {
    localStorage.removeItem('userData');
    this.router.navigate([`/`]);
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}
