import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommunityService} from '../communities-list/community.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-community',
  templateUrl: './add-community.component.html',
  styleUrls: ['./add-community.component.css']
})
export class AddCommunityComponent implements OnInit {

  addCommunityForm: FormGroup;

  constructor(
    private router: Router,
    private communityService: CommunityService
  ) {
  }

  ngOnInit(): void {
    this.addCommunityForm = new FormGroup({
      title: new FormControl('', [Validators.required])
    });
  }

  onSubmit(): void {
    if (this.addCommunityForm.status === 'VALID') {
      this.communityService.insert(this.addCommunityForm.value).subscribe(res => {
        this.router.navigate(['/communities-list']);
      });
    }
  }

}
