<div class="container-fluid app-main-box">
  <div class="row main-row">
    <div class="navbar-col" *ngIf="user">
      <app-main-navbar></app-main-navbar>
    </div>
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
