<div *ngIf="places">
  <app-list-title
    title="מקומות"
    image="https://api.hanadiv.org/assets/Icons_Places_Grey.svg"
    subTitle="{{totalText}}"
    filteredResults="{{numberOfFilteredResults}}">
  </app-list-title>

  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <form class="d-flex w-100" [formGroup]="filterForm">
          <label class="filter-label">
            <select formControlName="festivalID" class="form-control" (change)="onFilter()">
              <option value="">בחירת פסטיבל</option>
              <option *ngFor="let item of festivals" [ngValue]="item.festivalID">{{item.name}}</option>
            </select>
          </label>
          <label class="filter-label">
            <select formControlName="matchedWithInitiative" class="form-control" (change)="onFilter()">
              <option value="">האם משודך ליוזמה</option>
              <option *ngFor="let item of matchedWithInitiativeOptions" [ngValue]="item.value">{{item.title}}</option>
            </select>
          </label>
          <label class="filter-label">
            <select formControlName="areaSetting" class="form-control" (change)="onFilter()">
              <option value="">בחירת סוג החלל</option>
              <option *ngFor="let item of areaSettings" [ngValue]="item.title">{{item.title}}</option>
            </select>
          </label>
        </form>
        <fa-icon class="pointer font-size-20 ml-5 color-red" *ngIf="filtered" [icon]="faFilter" (click)="clearFilter()"></fa-icon>
      </div>
      <div>
        <label>
          <input type="text" [(ngModel)]="search" class="form-control" (ngModelChange)="countSearchResults()">
        </label>
      </div>
    </div>

    <table class="table text-center">
      <tr>
        <td class="pointer" (click)="sort('placeID')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מס' מזהה</span>
        </td>
        <td class="w-15">שם וכתובת</td>
        <td class="w-10 pointer" (click)="sort('host')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>איש קשר</span>
        </td>
        <td class="w-5 pointer" (click)="sort('numberOfEventsAtPlace')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>יוזמות משוייכות</span>
        </td>
        <td class="w-1 pointer" (click)="sort('maxNumberOfPeople')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>תפוסה מקסימלית</span>
        </td>
        <td class="pointer">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>שכונה</span>
        </td>
        <td class="pointer" (click)="sort('areaSetting')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>סוג חלל</span>
        </td>
        <td class="w-5">ימי פעילות</td>
        <td class="w-15">פעולות</td>
      </tr>
      <tr *ngFor="let item of places | filterPlacesListByObject: filterByObject | filterPlacesList:search | orderBy: key: reverse">
        <td>{{item.placeID}}</td>
        <td>
          <span class="font-weight-bold">{{item.description}}</span>
          <br>
          <span *ngIf="item.street">{{item.street}}</span>
          <span *ngIf="item.houseNumber"> {{item.houseNumber}}</span>
          <span *ngIf="item.entrance"> {{item.entrance}}</span>
          <span *ngIf="item.city">,{{item.city}}</span>
        </td>
        <td>{{item.host}}</td>
        <td>
          <span>{{item.numberOfEventsAtPlace}}</span>
          <span>/{{item.numberOfInitiativesWillingToHost}}</span>
        </td>
        <td>{{item.maxNumberOfPeople}}</td>
        <td>{{item.neighborhood}}</td>
        <td>{{item.areaSetting}}</td>
        <td>{{item.availableDays}}</td>
        <td class="d-flex justify-content-space-evenly">
          <button class="btn btn-success" routerLink="/place/{{item.placeID}}">
            <fa-icon [icon]="faEye"></fa-icon>
          </button>
          <button class="btn btn-info" routerLink="/edit-place/{{item.placeID}}">
            <fa-icon [icon]="faEdit"></fa-icon>
          </button>
          <button class="btn btn-danger" (click)="delete(item.placeID)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
