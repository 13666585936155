import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {User} from '../shared/models/user.model';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DOCUMENT} from '@angular/common';

interface AuthResponseData {
  name: string;
  email: string;
  token: string;
  expiresIn: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);
  queueList = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
  ) {
  }

  onLogin(params): Observable<any> {
    const data = {
      email: params.email,
      password: params.password
    };
    return this.http.post<AuthResponseData>(`${environment.apiURL}/auth`, JSON.stringify(data)).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(resData.userID, resData.name, resData.email, resData.token, resData.expiresIn, resData.authorizedByUserID, resData.manageCommunity, resData.manageFestival, resData.manageSystem, resData.permission, resData.permissionID, resData.adminHomePage);
      })
    );
  }

  autoLogin(): void {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.userID,
      userData.name,
      userData.email,
      userData._token,
      new Date(userData._tokenExpiration),
      userData.authorizedByUserID,
      userData.manageCommunity,
      userData.manageFestival,
      userData.manageSystem,
      userData.permission,
      userData.permissionID,
      userData.adminHomePage,
    );

    const data = {
      email: userData.email,
      token: userData._token
    };
    if (loadedUser.token) {
      this.user.next(loadedUser);
      this.http.post(`${environment.apiURL}/auth`, JSON.stringify(data)).subscribe((user: User) => {
        if (user) {
          this.setUserData(user.userID, user.name, user.email, user.token, null, user.authorizedByUserID, user.manageCommunity, user.manageFestival, user.manageSystem, user.permission, user.permissionID, user.adminHomePage);
          this.user.next(user);
        }
      });
    }
  }

  onLogout(): void {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.setItem('userData', null);
  }

  onRegister(params): Observable<any> {
    const data = {
      action: 'register',
      firstName: params.firstName,
      lastName: params.lastName,
      email: params.email,
      password: params.password,
      phone: params.phone,
      cityID: null,
      street: null,
      houseNumber: null

    };

    if (params.city) {
      data.cityID = params.city;
    }
    if (params.street) {
      data.street = params.street;
    }
    if (params.houseNumber) {
      data.houseNumber = params.houseNumber;
    }
    return this.http.put<AuthResponseData>(`${environment.apiURL} / user`, JSON.stringify(data), {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
    }).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(resData.userID, resData.name, resData.email, resData.token, resData.expiresIn, resData.authorizedByUserID, resData.manageCommunity, resData.manageFestival, resData.manageSystem, resData.permission, resData.permissionID, resData.adminHomePage);
      })
    );
  }

  handleError(errorRes: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Error Logging in';
    if (!errorRes.error || !errorRes.error.code) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email already exists';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'This email does not exist';
        break;
    }
    return throwError(errorMessage);
  }

  public setUserData(userID: number, name: string, email: string, token: string, expiresIn: number, authorizedByUserID: number, manageCommunity: boolean, manageFestival: boolean, manageSystem: boolean, permission: string, permissionID: number, adminHomePage: string): void {
    const expirationDate = new Date(
      new Date().getTime() + expiresIn * 1000
    );
    const user = new User(
      userID,
      name,
      email,
      token,
      expirationDate,
      authorizedByUserID,
      manageCommunity,
      manageFestival,
      manageSystem,
      permission,
      permissionID,
      adminHomePage,
    );
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
  }

  onLoginSuccess(): void {
  }

  onForgotPassword(email): Observable<any> {
    const domain = this.document.location.hostname;
    const subdomain = domain.split('.')[0];
    return this.http.get(`${environment.apiURL} / auth ? action = forgot - password & email =${email}&subdomain=${subdomain}`
    );
  }

  onResetPassword(params): Observable<any> {
    const data = {
      action: 'reset-password',
      email: params.email,
      password: params.password,
      token: params.token,
    };
    return this.http.put(
      `${environment.apiURL}/auth`
      , JSON.stringify(data)).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(
          resData.userID,
          resData.name,
          resData.email,
          resData.token,
          resData.expiresIn,
          resData.authorizedByUserID,
          resData.manageCommunity,
          resData.manageFestival,
          resData.manageSystem,
          resData.permission,
          resData.permissionID,
          resData.adminHomePage,
        );
      })
    );
  }

}
