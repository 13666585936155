import {Pipe, PipeTransform} from '@angular/core';
import {Community} from '../shared/models/community.model';

@Pipe({
  name: 'filterCommunitiesList'
})
export class FilterCommunitiesListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): Community[] {
    if (!value || value.length === 0 || args.length === 0 || !args) {
      return;
    }
    let filteredObjects;
    filteredObjects = value.filter(item => {
      return (
        (item.title && item.title.includes(args)) ||
        (item.nextFestivalDate && item.nextFestivalDate.includes(args))
      );
    });
    return filteredObjects;
  }

}
