<div *ngIf="event">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{event.eventID}}"
    createDate="{{event.insertTimeStamp}}"></app-page-title>
  <app-edit-item-button editItemLink="/edit-event/{{event.eventID}}"></app-edit-item-button>
  <div class="container-fluid main-box">
    <div class="row">
      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>שם היוצר/ת</span>
          </div>
          <div class="col-12 data-value" *ngIf="event.initiativeOwnerName">
            <a routerLink="/user/{{event.initiativeOwnerUserID}}">{{event.initiativeOwnerName}}</a>
          </div>
        </div>

        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>פרטי היוזמה</span>
          </div>
          <div class="col-12 data-value" *ngIf="!event.virtual">
            <div>
              <a routerLink="/initiative/{{event.initiativeID}}">{{event.title}}</a>
            </div>
          </div>
        </div>

        <div class="row data-box">
          <div class="col-12 data-value">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faClock"></fa-icon>
            <span>{{event.eventDate}}, </span>
            <span>{{event.eventHour}}</span>
          </div>
        </div>

        <div class="row data-box" *ngIf="event.band">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>שם ההרכב</span>
          </div>
          <div class="col-12 data-value" *ngIf="event.band">{{event.band}}</div>
        </div>

        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>פרטי המקום</span>
          </div>
          <div class="col-12 data-value" *ngIf="!event.virtual">
            <div>
              <a routerLink="/place/{{event.placeID}}">{{event.placeDescription}}</a>
            </div>
            <div>{{event.street}} {{event.houseNumber}}, {{event.city}}</div>
          </div>
          <div class="col-12 data-value" *ngIf="event.virtual">
            <div class="text-success">אירוע וירטואלי</div>
          </div>
        </div>

        <div class="row data-box" *ngIf="event.virtual">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faVideo"></fa-icon>
            <span>קישור לאירוע דיגיטלי</span>
          </div>
          <div class="col-12 data-value">
            <a href="{{event.liveStreamURL}}" target="_blank">{{event.liveStreamURL}}</a>
          </div>
        </div>

        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faBox"></fa-icon>
            <span>קטגוריה</span>
          </div>
          <div class="col-12 data-value">{{event.genre}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faTags"></fa-icon>
            <span>תגיות</span>
          </div>
          <div class="col-12 data-value" *ngFor="let item of event.tags">{{item.title}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faAddressCard"></fa-icon>
            <span>אנשי קשר</span>
          </div>
          <div class="col-12 data-value">
            <div>יוזמים/ות</div>
            <div class="d-flex justify-content-between p-0">
              <a class="link font-weight-bold" routerLink="/user/{{event.initiativeOwnerUserID}}">{{event.initiativeOwnerName}}</a>
              <a class="color-black font-weight-bold" href="tel:{{event.initiativeOwnerPhone}}" target="_blank">{{event.initiativeOwnerPhone}}</a>
            </div>
          </div>
          <div class="col-12 data-value">
            <div>בעלי הבית</div>
            <div class="d-flex justify-content-between p-0">
              <a class="link font-weight-bold" routerLink="/user/{{event.placeOwnerUserID}}">{{event.placeOwnerName}}</a>
              <a class="color-black font-weight-bold" href="tel:{{event.placeOwnerPhone}}" target="_blank">{{event.placeOwnerPhone}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>תמונה ראשית</span>
          </div>
          <div class="col-12 data-value"></div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>פירוט ההרכב</span>
          </div>
          <div class="col-12 data-value" *ngFor="let item of event.participants">{{item.firstName}} {{item.lastName}} {{item.role}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>תיאור האירוע</span>
          </div>
          <div class="col-12 data-value">{{event.details}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>פרטים טכניים מיוחדים</span>
          </div>
          <div class="col-12 data-value"></div>
        </div>
      </div>

      <div class="col-4">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>קהל יעד</span>
          </div>
          <div class="col-12 data-value">{{event.public}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>כמה אנשים יכולים להשתתף באירוע</span>
          </div>
          <div class="col-12 data-value">{{event.totalNumberOfPeopleThatCanAttend}}</div>
        </div>

        <!-- event attendees-->
        <div class="row data-box" *ngIf="event.attendees.eventAttendees.length>0">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>נרשמים</span>
          </div>
          <div class="col-12 data-value">
            <ul class="list-group p-0 mt-2">
              <li class="list-group-item font-size-14" *ngFor="let item of event.attendees.eventAttendees">
                <span class="ml-4">
                  <a class="color-black" routerLink="/user/{{item.userID}}">{{item.firstName}} {{item.lastName}}</a>
                </span>
                <span class="ml-4">
                  <a class="color-black " href="tel:{{item.phone}}" target="_blank">{{item.phone}}</a>
                </span>
                <span class="font-size-14 ml-2">נרשם/ה ב:</span>
                <span class="font-size-14">{{formatTimeStamp(item.insertTimeStamp)}}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- waiting list -->
        <div class="row data-box" *ngIf="event.attendees.waitingList.length>0">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>רשימת ממתינים/ות</span>
          </div>
          <div class="col-12 data-value">
            <ul class="list-group p-0 mt-2">
              <li class="list-group-item font-size-14" *ngFor="let item of event.attendees.waitingList">
                <span class="ml-4">
                  <a class="color-black" routerLink="/user/{{item.userID}}">{{item.firstName}} {{item.lastName}}</a>
                </span>
                <span class="ml-4">
                  <a class="color-black " href="tel:{{item.phone}}" target="_blank">{{item.phone}}</a>
                </span>
                <span class="font-size-14 ml-2">נרשם/ה ב:</span>
                <span class="font-size-14">{{formatTimeStamp(item.insertTimeStamp)}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

