import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {CulturalInitiative} from '../models/cultural-initiative.model';

@Injectable({
  providedIn: 'root'
})
export class CulturalInitiativesService {

  culturalInitiativeSubject = new Subject<CulturalInitiative[]>();
  url = `${environment.apiURL}/cultural-initiatives`;

  constructor(private http: HttpClient) {
  }

  get(subdomain = null): void {
    let url = this.url;
    if (subdomain) {
      url += `?festival=${subdomain}`;
    }
    this.http.get(url).subscribe((res: CulturalInitiative[]) => {
      this.culturalInitiativeSubject.next(res);
    });
  }

  update(data): Observable<any> {
    return this.http.put(this.url, JSON.stringify(data));
  }

  insert(data): Observable<any> {
    return this.http.post(this.url, JSON.stringify(data));
  }
}
