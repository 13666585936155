import {Pipe, PipeTransform} from '@angular/core';
import {ManageEvent} from '../shared/models/manage-event.model';

@Pipe({
  name: 'filterEventsList'
})
export class FilterEventsListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): ManageEvent[] {
    if (!value || value.length === 0 || args.length === 0 || !args) {
      return;
    }
    const resultArray = [];
    for (const item of value) {
      if (
        (item.title && item.eventID.toString().includes(args)) ||
        (item.title && item.title.includes(args)) ||
        (item.about && item.about.includes(args)) ||
        (item.city && item.city.includes(args)) ||
        (item.details && item.details.includes(args)) ||
        (item.eventFullTimeStamp && item.eventFullTimeStamp.includes(args)) ||
        (item.genre && item.genre.includes(args)) ||
        (item.initiativeDescription && item.initiativeDescription.includes(args)) ||
        (item.initiativeOwnerName && item.initiativeOwnerName.includes(args)) ||
        (item.initiativeOwnerPhone && item.initiativeOwnerPhone.includes(args)) ||
        (item.website && item.website.includes(args)) ||
        (item.liveStreamURL && item.liveStreamURL.includes(args)) ||
        (item.placeDescription && item.placeDescription.includes(args)) ||
        (item.placeOwnerName && item.placeOwnerName.includes(args)) ||
        (item.placeOwnerPhone && item.placeOwnerPhone.includes(args)) ||
        (item.public && item.public.includes(args)) ||
        (item.street && item.street.includes(args))
      ) {
        resultArray.push(item);
      }
    }
    return resultArray;
  }
}
