<div *ngIf="user">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{user.userID}}"
    createDate="{{user.insertTimeStamp}}"></app-page-title>
  <app-edit-item-button editItemLink="/edit-user/{{user.userID}}"></app-edit-item-button>
  <div class="container-fluid main-box">
    <div class="row">
      <div class="col-4">
        <div class="row data-box" *ngIf="user.permissionID===1">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faStar"></fa-icon>
            <span>מנהל/ת מערכת</span>
          </div>
        </div>
        <div class="row data-box" *ngIf="user.permissionID===2">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faStar"></fa-icon>
            <span>מנהל/ת קהילה</span>
          </div>
          <div class="col-12 data-value" *ngFor="let item of user.managerOfCommunities">{{item.title}}</div>
        </div>
        <div class="row data-box" *ngIf="user.permissionID===3">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faStar"></fa-icon>
            <span>מנהל/ת פסטיבל</span>
          </div>
          <div class="col-12 data-value" *ngFor="let item of user.managerOfFestivals">{{item.title}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span *ngIf="user.communities.length>1">קהילות</span>
            <span *ngIf="user.communities.length<=1">קהילה</span>
          </div>
          <div class="col-12 data-value" *ngFor="let item of user.communities">{{item}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faPhone"></fa-icon>
            <a href="tel:{{user.phone}}" target="_blank">{{user.phone}}</a>
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faEnvelope"></fa-icon>
            <a href="mailto:{{user.email}}" target="_blank">{{user.email}}</a>
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>כתובת</span>
          </div>
          <div class="col-12 data-value">
            <span *ngIf="user.street"> {{user.street}}</span>
            <span *ngIf="user.houseNumber"> {{user.houseNumber}}</span>
            <span *ngIf="user.street || user.houseNumber">,</span>
            <span *ngIf="user.city"> {{user.city}}</span>
          </div>
        </div>
      </div>

      <div class="col-4" *ngIf="user.events.length>0">
        <div class="data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>אירועים מקושרים</span>
          </div>
          <div class="image-container pointer mt-4 text-center" [style.backgroundImage]="'url('+ item.path +')'"
               *ngFor="let item of user.events" routerLink="/event/{{item.eventID}}">
            <div class="after p-3 text-right">
              <div class="image-text-box">
                <div class="font-size-17 font-weight-bold">{{item.title}}</div>
                <div class="font-size-12 color-white-greyish" *ngIf="item.street">
                  <span>מקום אירוח: </span>
                  <span>{{item.street}} </span>
                  <span *ngIf="item.houseNumber">{{item.houseNumber}} </span>
                  <span *ngIf="item.entrance">{{item.entrance}} </span>
                </div>
                <div class="font-size-12 color-white-greyish">
                  <span>סטטוס: </span>
                  <span>{{getStatusTitle(item.status)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="data-box" *ngIf="user.places.length>0">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>מקומות מקושרים</span>
          </div>
          <div class="image-container pointer mt-4 text-center" [style.backgroundImage]="'url('+ place.path +')'"
               *ngFor="let place of user.places" routerLink="/place/{{place.placeID}}">
            <div class="after p-3 text-right">
              <div class="image-text-box">
                <div class="font-size-12 color-white-greyish">
                  <span>המארח: </span>
                  <span>{{place.host}}</span>
                </div>
                <div class="font-size-17 font-weight-bold">{{place.description}}</div>
                <div class="font-size-12">
                  <span>עד </span>
                  <span>{{place.maxNumberOfPeople}}</span>
                  <span> אנשים</span>
                </div>
                <div class="font-size-12">
                  <span>{{place.numberOfEventsAtPlace}}/{{place.numberOfInitiativesWillingToHost}}</span>
                  <span> יוזמות כבר שודכו למקום</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-box" *ngIf="user.initiatives.length>0">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>יוזמות מקושרות</span>
          </div>
          <div class="image-container pointer mt-4 text-center" [style.backgroundImage]="'url('+ item.path +')'"
               *ngFor="let item of user.initiatives" routerLink="/initiative/{{item.initiativeID}}">
            <div class="after p-3 text-right">
              <div class="image-text-box">
                <div class="font-size-17">{{item.title}}</div>
                <div class="font-size-12">{{item.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

