import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManageEvent} from '../shared/models/manage-event.model';
import {ManageEventService} from '../shared/services/manage-event.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {faUsers, faAddressCard, faVideo, faHome, faClock, faTags, faBox, faPhone} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: [
    '../shared/styles/page.css',
    './event.component.css'
  ]
})
export class EventComponent implements OnInit, OnDestroy {

  event: ManageEvent;
  eventSubscription: Subscription;
  title: string;
  subTitle: string;
  faUsers = faUsers;
  faAddressCard = faAddressCard;
  faVideo = faVideo;
  faHome = faHome;
  faClock = faClock;
  faTags = faTags;
  faBox = faBox;
  faPhone = faPhone;

  constructor(
    private manageEventService: ManageEventService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const eventID = this.route.snapshot.paramMap.get('eventID');
    this.eventSubscription = this.manageEventService.get(eventID).subscribe(data => {
      this.event = data;
      this.title = data.title;
      this.subTitle = data.details;
    });
  }

  formatTimeStamp(date): string {
    return moment(date).format('HH:mm DD-MM-YYYY');
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

}
