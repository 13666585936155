<div class="container-fluid mb-7" *ngIf="user">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{user.userID}}"
    createDate="{{user.insertTimeStamp}}"></app-page-title>
  <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
    <div>
      <label class="mt-3">
        <span>שם פרטי</span>
        <input type="text" class="form-control" placeholder="שם פרטי" formControlName="firstName">
      </label>
      <label class="mt-3">
        <span>שם משפחה</span>
        <input type="text" class="form-control" placeholder="שם משפחה" formControlName="lastName">
      </label>
      <label class="mt-3">
        <span>אימייל</span>
        <input type="text" class="form-control" placeholder="אימייל" formControlName="email">
      </label>
      <label class="mt-3">
        <span>טלפון</span>
        <input type="text" class="form-control" placeholder="טלפון" formControlName="phone">
      </label>
      <label class="mt-3">
        <span>עיר</span>
        <select class="form-control" formControlName="cityID">
          <option value="0">יישוב</option>
          <option *ngFor="let city of cities" [ngValue]="city.cityID">{{ city.title }}</option>
        </select>
      </label>
      <label class="mt-3">
        <span>רחוב</span>
        <input type="text" class="form-control" placeholder="רחוב" formControlName="street">
      </label>
      <label class="mt-3">
        <span>מספר בית</span>
        <input type="text" class="form-control" placeholder="מספר בית" formControlName="houseNumber">
      </label>
    </div>
    <label class="mt-3">
      <span>הרשאה</span>
      <select formControlName="permissionID" class="form-control" (change)="resetMessage()">
        <option value="0">ללא הרשאת ניהול</option>
        <option *ngFor="let item of permissions" [ngValue]="item.permissionID">{{item.permission}}</option>
      </select>
    </label>
    <label class="mt-3" *ngIf="editUserForm.controls.permissionID.value==2">
      <span>ניהול קהילה</span>
      <select formControlName="communityID" class="form-control" (change)="resetMessage()">
        <option value="0">בחירת אפשרות</option>
        <option *ngFor="let item of communities" [ngValue]="item.communityID">{{item.title}}</option>
      </select>
    </label>
    <label class="mt-3" *ngIf="editUserForm.controls.permissionID.value==3">
      <span>ניהול פסטיבל</span>
      <select formControlName="festivalID" class="form-control" (change)="resetMessage()">
        <option value="0">בחירת אפשרות</option>
        <option *ngFor="let item of festivals" [ngValue]="item.festivalID">{{item.name}}</option>
      </select>
    </label>
    <div class="alert alert-danger text-center font-weight-bold" *ngIf="message">{{message}}</div>
    <div class="text-center mt-5">
      <button class="btn btn-submit font-size-18 pr-3 pl-3 w-75" type="submit">עדכון פרטי חבר/ת הקהילה</button>
    </div>
  </form>
  <!--<div class="text-center mt-5 mb-10">
    <button class="btn btn-delete font-size-18 pr-3 pl-3 w-75" (click)="delete()" type="button" [disabled]="formSubmitted">
      <fa-icon [icon]="faTrash" class="float-right"></fa-icon>
      <span>מחיקה</span>
    </button>
  </div>-->
</div>
