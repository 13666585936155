import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {faEye, faEdit, faTrash, faSort, faFilter} from '@fortawesome/free-solid-svg-icons';
import {ManagePlace} from '../shared/models/manage-place.model';
import {ManagePlaceService} from '../shared/services/manage-place-service.service';
import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import {ManageDataService} from '../shared/services/manage-data.service';
import {FilterPlacesListByObjectPipe} from './filter-places-list-by-object.pipe';
import {Festival} from '../shared/models/festival.model';
import {AreaSettingModel} from '../shared/models/publics-area-settings.model';
import {FilterPlacesListPipe} from './filter-places-list.pipe';

@Component({
  selector: 'app-places-list',
  templateUrl: './places-list.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './places-list.component.css'
  ]
})
export class PlacesListComponent implements OnInit, OnDestroy {

  places: ManagePlace[];
  placesSubscription: Subscription;
  festivalsSubscription: Subscription;
  areaSettingsSubscription: Subscription;
  deleteSubscription: Subscription;
  festivals: Festival[];
  areaSettings: AreaSettingModel[];
  filterForm: FormGroup;
  totalText: string;
  key = 'placeID';
  reverse = false;
  search: string;
  filtered: boolean;
  numberOfFilteredResults: number;
  filterByObject: { festivalID: number, matchedWithInitiative: number, areaSetting: string };
  matchedWithInitiativeOptions: { title: string, value: number }[];
  days: { title: string, value: number }[];
  faEye = faEye;
  faEdit = faEdit;
  faTrash = faTrash;
  faSort = faSort;
  faFilter = faFilter;

  constructor(
    private placeService: ManagePlaceService,
    private manageDataService: ManageDataService,
    private filterByText: FilterPlacesListPipe,
    private filterByObjectPipe: FilterPlacesListByObjectPipe,
  ) {
  }

  ngOnInit(): void {
    this.getPlaces();

    this.buildForm();

    this.festivalsSubscription = this.manageDataService.getFestivals().subscribe(festivals => {
      this.festivals = festivals;
    });

    this.areaSettingsSubscription = this.manageDataService.getAreaSettings().subscribe(areaSettings => {
      this.areaSettings = areaSettings;
    });

    this.matchedWithInitiativeOptions = [
      {title: 'לא משודך', value: 0},
      {title: 'כן משודך', value: 1},
    ];
  }

  getPlaces(): void {
    this.placesSubscription = this.placeService.get().subscribe(data => {
      this.places = data;

      const totalNumber = data.length;
      const reducer = (accumulator, item) => {
        const isMatched = item.numberOfEventsAtPlace > 0 ? 1 : 0;
        return accumulator + isMatched;
      };
      const totalNumberOfMatched = data.reduce(reducer, 0);

      const text = 'מקומות משודכים ליוזמות';
      this.totalText = `${text} ${totalNumberOfMatched}/${totalNumber}`;

      for (const item of this.places) {

        const sortedDatesArray = item.available.sort((a, b) => {
          // @ts-ignore
          return new Date(a.date) - new Date(b.date);
        });

        const availableDaysString = sortedDatesArray.map(obj => {
          return moment(obj.date).locale('he').format('dd');
        });

        item.availableDays = availableDaysString.join(', ');
      }
    });
  }

  setFilterDays(): void {
    console.log(this.places);
  }

  buildForm(): void {
    this.filterForm = new FormGroup({
      festivalID: new FormControl(''),
      matchedWithInitiative: new FormControl(''),
      areaSetting: new FormControl(''),
    });
  }

  onFilter(): void {
    this.filtered = true;
    this.filterByObject = this.filterForm.value;
    if (!this.filterByObject.festivalID && !this.filterByObject.matchedWithInitiative.toString() && !this.filterByObject.areaSetting) {
      this.clearFilter();
    } else {
      this.countFilteredResults();
    }
  }

  clearFilter(): void {
    this.filtered = false;
    this.filterForm.controls.festivalID.setValue('');
    this.filterForm.controls.matchedWithInitiative.setValue('');
    this.filterForm.controls.areaSetting.setValue('');
    this.filterByObject = this.filterForm.value;
    this.numberOfFilteredResults = null;
  }

  countFilteredResults(): void {
    const filteredCulturalInitiatives = this.filterByObjectPipe.transform(this.places, this.filterForm.value);
    this.numberOfFilteredResults = filteredCulturalInitiatives.length;
  }

  countSearchResults(): void {
    const filteredResults = this.filterByText.transform(this.places, this.search);
    if (this.search === '') {
      this.numberOfFilteredResults = null;
    } else {
      this.numberOfFilteredResults = filteredResults.length;
    }
  }

  sort(key): void {
    this.key = key;
    this.reverse = !this.reverse;
  }

  delete(id): void {
    const confirmDelete = confirm(`האם את/ה בטוח שאת/ה רוצה למחוק את מקום מספר ${id}?`);
    if (confirmDelete) {
      this.deleteSubscription = this.placeService.delete(id).subscribe(res => {
        this.getPlaces();
      });
    }
  }

  ngOnDestroy(): void {
    this.placesSubscription.unsubscribe();
    this.festivalsSubscription.unsubscribe();
    this.areaSettingsSubscription.unsubscribe();
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

}
