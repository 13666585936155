import {Component, OnDestroy, OnInit} from '@angular/core';
import {CulturalInitiative} from '../shared/models/cultural-initiative.model';
import {Subscription} from 'rxjs';
import {CulturalInitiativesService} from '../shared/services/cultural-initiatives-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {City} from '../shared/models/city.model';
import {ManageDataService} from '../shared/services/manage-data.service';
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Genre} from '../shared/models/genre.model';

@Component({
  selector: 'app-edit-cultural-initiative',
  templateUrl: './edit-cultural-initiative.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './edit-cultural-initiative.component.css'
  ]
})
export class EditCulturalInitiativeComponent implements OnInit, OnDestroy {

  culturalInitiative: CulturalInitiative;
  culturalInitiativesSubscription: Subscription;
  updateCulturalInitiativeSubscription: Subscription;
  citiesSubscription: Subscription;
  genresSubscription: Subscription;
  addGenreSubscription: Subscription;
  uploadImageSubscription: Subscription;
  editCulturalInitiativeForm: FormGroup;
  addGenreForm: FormGroup;
  cities: City[];
  title: string;
  subTitle: string;
  tabs: {
    about: true,
    navigation: false,
    team: false,
    thanks: false,
    sleep: false,
    updates: false,
  };
  date: { year: number, month: number };
  genres;
  imageSource;
  sponsorDesktopSource;
  sponsorMobileSource;
  faTrash = faTrash;
  faPlus = faPlus;

  constructor(
    private culturalInitiativesService: CulturalInitiativesService,
    private manageDataService: ManageDataService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.tabs = {
      about: true,
      navigation: false,
      team: false,
      thanks: false,
      sleep: false,
      updates: false
    };
    const subdomain = this.route.snapshot.paramMap.get('subdomain');
    this.culturalInitiativesService.get(subdomain);
    this.buildForm();
    this.getGenres();

    this.culturalInitiativesSubscription = this.culturalInitiativesService.culturalInitiativeSubject.subscribe((data: CulturalInitiative[]) => {
      this.culturalInitiative = data[0];
      this.title = `עריכת ${this.culturalInitiative.name}`;
      this.subTitle = `${this.culturalInitiative.community}`;

      this.citiesSubscription = this.manageDataService.getCities().subscribe(cities => {
        this.cities = cities;
        this.setFormValues();
      });
    });

    this.uploadImageSubscription = this.manageDataService.uploadResponse.subscribe(res => {
      if (res) {
        const uploadedFileData = res.body;
        this.setImageSource(uploadedFileData);
      }
    });
  }

  getGenres(): void {
    this.genresSubscription = this.manageDataService.getGenres().subscribe(genres => {
      this.genres = genres;
    });
  }

  listenToTextEditorEmits(key, value: string): void {
    this.editCulturalInitiativeForm.controls[key].setValue(value);
  }

  buildForm(): void {
    this.editCulturalInitiativeForm = new FormGroup({
      festivalID: new FormControl(''),
      name: new FormControl(''),
      cityID: new FormControl(''),
      fromDate: new FormControl(''),
      untilDate: new FormControl(''),
      subdomain: new FormControl(''),
      fileHash: new FormControl(''),
      sponsorDesktop: new FormControl(''),
      sponsorMobile: new FormControl(''),
      archived: new FormControl(''),
      about: new FormControl(''),
      navigation: new FormControl(''),
      team: new FormControl(''),
      thanks: new FormControl(''),
      sleep: new FormControl(''),
      updates: new FormControl(''),
      facebook: new FormControl(''),
      instagram: new FormControl(''),
      youtube: new FormControl(''),
      metadata: new FormControl(''),
      genres: new FormControl('')
    });

    this.addGenreForm = new FormGroup({
      genre: new FormControl('')
    });
  }

  setFormValues(): void {
    this.editCulturalInitiativeForm.controls.festivalID.setValue(this.culturalInitiative.festivalID);
    this.editCulturalInitiativeForm.controls.name.setValue(this.culturalInitiative.name);
    this.editCulturalInitiativeForm.controls.about.setValue(this.culturalInitiative.about);
    this.editCulturalInitiativeForm.controls.navigation.setValue(this.culturalInitiative.navigation);
    this.editCulturalInitiativeForm.controls.team.setValue(this.culturalInitiative.team);
    this.editCulturalInitiativeForm.controls.subdomain.setValue(this.culturalInitiative.subdomain);
    this.editCulturalInitiativeForm.controls.fileHash.setValue(this.culturalInitiative.logo);
    this.editCulturalInitiativeForm.controls.sponsorDesktop.setValue(this.culturalInitiative.sponsorDesktop);
    this.editCulturalInitiativeForm.controls.sponsorMobile.setValue(this.culturalInitiative.sponsorMobile);
    this.editCulturalInitiativeForm.controls.archived.setValue(this.culturalInitiative.archived);
    const cityValue = this.culturalInitiative.cityID ? this.culturalInitiative.cityID.toString() : 0;
    this.editCulturalInitiativeForm.controls.cityID.setValue(cityValue);
    this.editCulturalInitiativeForm.controls.thanks.setValue(this.culturalInitiative.thanks);
    this.editCulturalInitiativeForm.controls.sleep.setValue(this.culturalInitiative.sleep);
    this.editCulturalInitiativeForm.controls.updates.setValue(this.culturalInitiative.updates);
    this.editCulturalInitiativeForm.controls.genres.setValue(this.culturalInitiative.genres);
    this.setDates();
  }

  setDates(): void {
    this.editCulturalInitiativeForm.controls.fromDate.setValue(this.culturalInitiative.fromDateObject);
    this.editCulturalInitiativeForm.controls.untilDate.setValue(this.culturalInitiative.untilDateObject);
  }

  addGenreFromList(genre: Genre): void {
    if (!this.doesGenresListIncludes(genre.title)) {
      this.culturalInitiative.genres.push(genre);
      this.submitNewGenre(genre.title);
    }
  }

  doesGenresListIncludes(genre: string): boolean {
    const genreInList = this.culturalInitiative.genres.find(item => item.title === genre);
    return !!genreInList;
  }

  onSubmitAddGenreForm(): void {
    const genre = this.addGenreForm.controls.genre.value;
    if (genre) {
      if (!this.doesGenresListIncludes(genre)) {
        this.submitNewGenre(genre);
      }
    }
  }

  private submitNewGenre(genre: string): void {
    this.addGenreSubscription = this.manageDataService.addGenre(this.culturalInitiative.festivalID, genre).subscribe(data => {
      this.addGenreForm.reset();
      if (data) {
        this.culturalInitiative.genres.push({
          genreID: data.genreID,
          title: data.title
        });
      }
    });
  }

  onSubmit(): void {
    this.updateCulturalInitiativeSubscription = this.culturalInitiativesService.update(this.editCulturalInitiativeForm.value).subscribe(res => {
      this.router.navigate(['/cultural-initiatives-list']);
    });
  }

  showTab(key): void {
    for (const [index, value] of Object.entries(this.tabs)) {
      this.tabs[index] = key === index;
    }
  }

  openAddGenreModal(modal): void {
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  removeGenre(genreID): void {
    this.addGenreSubscription = this.manageDataService.deleteGenre(this.culturalInitiative.festivalID, genreID).subscribe(data => {
      const removedIndex = this.culturalInitiative.genres.findIndex(item => item.genreID === genreID);
      this.culturalInitiative.genres.splice(removedIndex, 1);
    });
  }

  setImageSource(uploadedFile): void {
    if (uploadedFile) {
      switch (uploadedFile.imageTarget) {
        case 'sponsor-desktop':
          this.editCulturalInitiativeForm.controls.sponsorDesktop.setValue(uploadedFile.relativePath);
          this.sponsorDesktopSource = `${uploadedFile.path}`;
          break;
        case 'sponsor-mobile':
          this.editCulturalInitiativeForm.controls.sponsorMobile.setValue(uploadedFile.relativePath);
          this.sponsorMobileSource = `${uploadedFile.path}`;
          break;
        default: // logo
          this.editCulturalInitiativeForm.controls.fileHash.setValue(uploadedFile.relativePath);
          this.imageSource = `${uploadedFile.path}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.culturalInitiativesSubscription.unsubscribe();
    this.citiesSubscription.unsubscribe();
    this.genresSubscription.unsubscribe();
    if (this.addGenreSubscription) {
      this.addGenreSubscription.unsubscribe();
    }
    if (this.updateCulturalInitiativeSubscription) {
      this.updateCulturalInitiativeSubscription.unsubscribe();
    }
    if (this.uploadImageSubscription) {
      this.uploadImageSubscription.unsubscribe();
    }
  }

}
