import {Component, Input, OnInit} from '@angular/core';
import {faEdit} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-item-button',
  templateUrl: './edit-item-button.component.html',
  styleUrls: ['./edit-item-button.component.css']
})
export class EditItemButtonComponent implements OnInit {

  @Input() editItemLink: string;
  faEdit = faEdit;

  constructor() {
  }

  ngOnInit(): void {
  }

}
