import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserDetails} from '../shared/models/user-details.model';
import {Subscription} from 'rxjs';
import {ManageUserService} from '../shared/services/manage-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {City} from '../shared/models/city.model';
import {ManageDataService} from '../shared/services/manage-data.service';
import {Permission} from '../shared/models/permission.model';
import {Community} from '../shared/models/community.model';
import {Festival} from '../shared/models/festival.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './edit-user.component.css'
  ]
})
export class EditUserComponent implements OnInit, OnDestroy {

  user: UserDetails;
  userSubscription: Subscription;
  title: string;
  subTitle: string;
  editUserForm: FormGroup;
  staticDataSubscription: Subscription;
  communities: Community[];
  festivals: Festival[];
  cities: City[];
  permissions: Permission[];
  formSubmitted: boolean;
  faTrash = faTrash;
  message: string;

  constructor(
    private userService: ManageUserService,
    private dataService: ManageDataService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.buildForm();
    const userID = this.route.snapshot.paramMap.get('userID');
    this.userSubscription = this.userService.get(userID).subscribe(user => {
      this.user = user;
      this.title = `${this.user.firstName} ${this.user.lastName}`;
      this.subTitle = this.user.city;
      this.staticDataSubscription = this.dataService.getEditUserData().subscribe(data => {
        this.cities = data.cities;
        this.permissions = data.permissions;
        this.communities = data.communities;
        this.festivals = data.festivals;
        this.setFormData();
      });
    });
  }

  buildForm(): void {
    this.editUserForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      cityID: new FormControl(''),
      street: new FormControl(''),
      houseNumber: new FormControl(''),
      permissionID: new FormControl(''),
      communityID: new FormControl(''),
      festivalID: new FormControl(''),
    });
  }

  setFormData(): void {
    for (const key of Object.keys(this.user)) {
      if (this.editUserForm.controls[key]) {
        this.editUserForm.controls[key].setValue(this.user[key]);
      }
    }
    const cityValue = this.user.cityID ? this.user.cityID.toString() : 0;
    this.editUserForm.controls.cityID.setValue(cityValue);
    const userPermissionID = this.user.permissionID ? this.user.permissionID.toString() : 0;
    this.editUserForm.controls.permissionID.setValue(userPermissionID);
    const managerOfCommunityID = this.user.managerOfCommunities.length > 0 ? this.user.managerOfCommunities[0].communityID.toString() : 0;
    const managerOfFestivalID = this.user.managerOfFestivals.length > 0 ? this.user.managerOfFestivals[0].festivalID.toString() : 0;
    this.editUserForm.controls.communityID.setValue(managerOfCommunityID);
    this.editUserForm.controls.festivalID.setValue(managerOfFestivalID);
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.editUserForm.controls.permissionID.value === '2' && this.editUserForm.controls.communityID.value === 0) {
      this.message = 'נא לבחור קהילה';
      return;
    }
    if (this.editUserForm.controls.permissionID.value === '3' && this.editUserForm.controls.festivalID.value === 0) {
      this.message = 'נא לבחור פסטיבל';
      return;
    }
    if (this.editUserForm.status === 'VALID') {
      this.dataService.updateUser(this.user.userID, this.editUserForm.value).subscribe(res => {
        this.router.navigate([`/users-list`]);
      }, error => {
        this.message = error.error.text;
      });
    }
  }

  resetMessage(): void {
    this.message = null;
  }

  delete(): void {
    const confirmDelete = confirm('האם את/ה בטוח שאת/ה רוצה למחוק את חבר/ת הקהילה?');
    if (confirmDelete) {
      this.userService.delete(this.user.userID).subscribe(res => {
        this.router.navigate([`/users-list`]);
      });
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    if (this.staticDataSubscription) {
      this.staticDataSubscription.unsubscribe();
    }
  }
}
