<div class="container-fluid color-turquoise mb-3">
  <div class="row page-title">
    <div class="col-9">
      <img src="{{image}}" class="image ml-2" alt="">
      <span class="title">{{title}}</span>
      <div class="col-12 sub-title">{{subTitle}}</div>
    </div>
    <div class="col-3">
      <div class="row font-size-12">
        <div class="col-5">תאריך יצירה</div>
        <div class="col">{{createDate}}</div>
      </div>
      <div class="row font-size-12">
        <div class="col-5">ID</div>
        <div class="col">{{rowID}}</div>
      </div>
    </div>
  </div>
</div>
