<div *ngIf="place">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{place.placeID}}"
    createDate="{{place.placeCreateTimeStamp}}"></app-page-title>
  <app-edit-item-button editItemLink="/edit-place/{{place.placeID}}"></app-edit-item-button>
  <div class="container-fluid main-box">
    <div class="row">
      <div class="col">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faFlag"></fa-icon>
            <span>פסטיבל</span>
          </div>
          <div class="col-12 data-value">{{place.festivalName}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>כתובת</span>
          </div>
          <div class="col-12 data-value">{{getAddress()}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faFlag"></fa-icon>
            <span>אירועים שנקבעו למתחם/מוכנות לארח</span>
          </div>
          <div class="col-12 data-value">
            <span>נקבעו </span>
            <span>{{place.numberOfEventsAtPlace}}</span>
            <span> אירועים </span>
            <span>(מתוך </span>
            <span>{{place.numberOfInitiativesWillingToHost}}</span>
            <span> אפשריים)</span>
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faUsers"></fa-icon>
            <span>תפוסה</span>
          </div>
          <div class="col-12 data-value">
            <span>עד </span>
            <span>{{place.maxNumberOfPeople}}</span>
            <span> אנשים</span>
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>סוג המקום</span>
          </div>
          <div class="col-12 data-value">{{place.areaSetting}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faAddressCard"></fa-icon>
            <span>אנשי קשר</span>
          </div>
          <div class="col-12 data-value">
            <div class="d-flex justify-content-between p-0">
              <a class="link font-weight-bold" routerLink="/user/{{place.hostID}}">{{place.host}}</a>
              <a class="color-black font-weight-bold" href="tel:{{place.phone}}" target="_blank">{{place.phone}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="data-box">
          <div class="mb-3 d-flex">
            <div class="row col-12 justify-content-between">
              <div class="icon-box ml-2 mb-2">
                <img *ngIf="place.isAccessible" src="https://api.hanadiv.org/assets/Icons_Accecible.svg" class="place-icon" alt="תמונת נגישות">
                <img *ngIf="!place.isAccessible" src="https://api.hanadiv.org/assets/Icons_Not_Accecible.svg" class="place-icon" alt="תמונת נגישות">
                <div class="icon-title">נגיש</div>
              </div>
              <div class="icon-box ml-2 mb-2">
                <img *ngIf="place.canHostNoisyEvents" src="https://api.hanadiv.org/assets/Icons_Noise.svg" class="place-icon" alt="תמונת אפשר להרעיש">
                <img *ngIf="!place.canHostNoisyEvents" src="https://api.hanadiv.org/assets/Icons_No_Noice.svg" class="place-icon" alt="תמונת אפשר להרעיש">
                <div class="icon-title">אפשר להרעיש</div>
              </div>
              <div class="icon-box ml-2 mb-2">
                <img *ngIf="place.petFriendly" src="https://api.hanadiv.org/assets/Icons_Animals.svg" class="place-icon" alt="תמונת ידידותי לבעלי חיים">
                <img *ngIf="!place.petFriendly" src="https://api.hanadiv.org/assets/Icons_No_Animals.svg" class="place-icon" alt="תמונת ידידותי לבעלי חיים">
                <div class="icon-title">ידידותי לבעלי חיים</div>
              </div>
              <div class="icon-box ml-2 mb-2">
                <img *ngIf="place.hasParking" src="https://api.hanadiv.org/assets/Icons_Parking.svg" class="place-icon" alt="תמונת חנייה">
                <img *ngIf="!place.hasParking" src="https://api.hanadiv.org/assets/Icons_No_Parking.svg" class="place-icon" alt="תמונת חנייה">
                <div class="icon-title">יש חניה</div>
              </div>
              <div class="icon-box ml-2 mb-2">
                <img *ngIf="place.piano" src="https://api.hanadiv.org/assets/Icons_Piano.svg" class="place-icon" alt="תמונת פסנתר">
                <img *ngIf="!place.piano" src="https://api.hanadiv.org/assets/Icons_No_Piano.svg" class="place-icon" alt="תמונת פסנתר">
                <div class="icon-title">יש פסנתר</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">תיאור המקום (יופיע בתוכנייה)</div>
          <div class="col-12 data-value">{{place.description}}</div>
        </div>
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">תמונות</div>
          <img *ngIf="place.path" src="{{place.path}}" class="image img-thumbnail" alt="">
        </div>
      </div>

      <div class="col">
        <div class="row data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">אירועים שנקבעו למקום הזה</div>
          <div class="col-12 data-value mt-3" *ngFor="let item of place.events">
            <div>
              <span>יום </span>
              <span>{{formatAsDay(item[0].fullDate)}}</span>
            </div>
            <div class="d-flex" *ngFor="let eventDetails of item">
              <div class="d-flex flex-row-reverse font-weight-bold ml-2">
                <span>{{formatAsHour(eventDetails.fromHour)}}</span>
                <span>-</span>
                <span>{{calculateAndFormatUntilHour(eventDetails.fromHour, eventDetails.duration)}}</span>
              </div>
              <div class="font-weight-bold">
                <div>
                  <a class="link" routerLink="/event/{{eventDetails.eventID}}">{{eventDetails.title}}</a>
                </div>
                <div class="font-size-14">
                  <span>מאת: </span>
                  <a class="link" routerLink="/user/{{eventDetails.initiativeOwnerID}}">{{eventDetails.host}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="data-box">
        <div class="col-12 font-size-14 font-weight-bold border-bottom">הערות נוספות</div>
        <div class="col-12 data-value">{{place.notes}}</div>
      </div>
    </div>
  </div>
</div>
