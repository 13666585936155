import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ManagePlace} from '../shared/models/manage-place.model';
import {faAddressCard, faUsers, faHome, faFlag} from '@fortawesome/free-solid-svg-icons';
import {ManagePlaceService} from '../shared/services/manage-place-service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: [
    '../shared/styles/page.css',
    './place.component.css'
  ]
})
export class PlaceComponent implements OnInit, OnDestroy {

  place: ManagePlace;
  initiativeSubscription: Subscription;
  title: string;
  subTitle: string;
  faAddressCard = faAddressCard;
  faUsers = faUsers;
  faHome = faHome;
  faFlag = faFlag;

  constructor(
    private placeService: ManagePlaceService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const placeID = this.route.snapshot.paramMap.get('placeID');
    this.initiativeSubscription = this.placeService.get(placeID).subscribe(data => {
      this.place = data[0];
      this.title = this.place.description;
      this.subTitle = this.getAddress();
    });
  }

  getAddress(): string {
    let address = '';
    if (this.place.street) {
      address = address.concat(this.place.street);
    }
    if (this.place.houseNumber) {
      address = address.concat(` ${this.place.houseNumber.toString()}`);
    }
    if (this.place.entrance) {
      address = address.concat(` ${this.place.entrance}`);
    }
    if (this.place.neighborhood) {
      address = address.concat(` ${this.place.neighborhood}`);
    }
    if (address.length > 0) {
      address = address.concat(`,`);
    }
    if (this.place.city) {
      address = address.concat(` ${this.place.city}`);
    }
    return address;
  }

  formatAsDay(date): string {
    return moment(date).locale('he').format('dddd');
  }

  calculateAndFormatUntilHour(fromHour, duration): string {
    return moment(fromHour, 'h:mm:ss A').add(duration, 'minutes').format('HH:mm');
  }

  formatAsHour(date): string {
    return moment(date, 'h:mm:ss A').format('HH:mm');
  }

  ngOnDestroy(): void {
    this.initiativeSubscription.unsubscribe();
  }

}
