import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  url = `${environment.apiURL}/communities`;

  constructor(private http: HttpClient) {
  }

  get(communityID = null): Observable<any> {
    let url = this.url;
    if (communityID) {
      url += `?communityID=${communityID}`;
    }
    return this.http.get(url);
  }

  insert(data): Observable<any> {
    return this.http.post(this.url, JSON.stringify(data));
  }

  update(data): Observable<any> {
    return this.http.put(this.url, JSON.stringify(data));
  }
}
