import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagePlaceService {

  url = `${environment.apiURL}/places`;

  constructor(private http: HttpClient) {
  }

  get(id = null): Observable<any> {
    let url = this.url;
    if (id) {
      url += `?placeID=${id}`;
    }
    return this.http.get(url);
  }

  update(data): Observable<any> {
    return this.http.put(`${this.url}`, JSON.stringify(data));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.url}?placeID=${id}`);
  }
}
